import React, { useEffect, useState } from 'react'
import "./Statistic.scss"
import { useDispatch, useSelector } from 'react-redux'
import { statsRequest } from '../../helpers/requests';
const Statistic = () => {
    const dispatch = useDispatch();
    const stats = useSelector(state => state.stats);
    const [cards, setCards] = useState({
        user: {label:"Total Users", value: 0},
        super_admin: {label: "Super Admins", value: 0},
        admin: {label: "Admins", value: 0},
        user_with_document: {label: "User With Documents", value: 0},
        user_with_document_percentage: {label: "User With Documents (%)", value: 0},
        user_with_answers: {label: "User With Answers", value: 0},
        user_with_answers_percentage: {label: "User With Answers (%)", value: 0},
        user_with_three_answers: {label: "Answered 3 Questions", value: 0},
        user_with_three_answers_percentage: {label: "Answered 3 Questions (%)", value: 0},
        user_with_five_answers: {label: "Answered 5 Questions", value: 0},
        user_with_five_answers_percentage: {label: "Answered 5 Questions (%)", value: 0},
        avg_answers_per_user: {label: "Avg Answers Per User", value: 0},
        three_sessions: {label: "3+ Sessions", value: 0},
        three_sessions_percentage: {label: "3+ Sessions (%)", value: 0},
        three_days: {label: "3 Days Streak", value: 0},
        three_days_percentage: {label: "3 Days Streak %", value: 0},
        two_minutes: {label: "2+ Minutes", value: 0},
        two_minutes_percentage: {label: "2+ Minutes (%)", value: 0},
        avg_two_minutes: {label: "Avg Session Length Above 2 Mins", value: 0},
        avg_session_length: {label: "Avg Session Length", value: 0},
    })

    useEffect(() => {
        let requestData = {dispatch};
        statsRequest(requestData);
    }, [])

    useEffect(() => {
        let data = {...cards};
        Object.keys(stats).map((key, index) => {
            data[key].value = stats[key];
        })
        setCards(data);
    }, [stats])

    return (
        <div className='stat_main'>
            <h1 className="title">App Statistics</h1>
            <div className="stat_content">
                <h3>Accounts</h3>
                <div className="card_main">
                    <div className="card">
                        <div className="subhead">{cards.super_admin.label}</div>
                        <div className="number">{cards.super_admin.value}</div>
                    </div>
                    <div className="card">
                        <div className="subhead">{cards.admin.label}</div>
                        <div className="number">{cards.admin.value}</div>
                    </div>
                    <div className="card">
                        <div className="subhead">{cards.user.label}</div>
                        <div className="number">{cards.user.value}</div>
                    </div>
                </div>
            </div>
            <div className="stat_content">
                <h3>Content</h3>
                <div className="card_main">
                    <div className="card">
                        <div className="subhead">{cards.user_with_document.label}</div>
                        <div className="number">{cards.user_with_document.value}</div>
                    </div>
                    <div className="card">
                        <div className="subhead">{cards.user_with_document_percentage.label}</div>
                        <div className="number">{cards.user_with_document_percentage.value}</div>
                    </div>
                </div>
            </div>
            <div className="stat_content">
                <h3>Usage</h3>
                <div className="card_main">
                    <div className="card">
                        <div className="subhead">{cards.user_with_answers.label}</div>
                        <div className="number">{cards.user_with_answers.value}</div>
                    </div>
                    <div className="card">
                        <div className="subhead">{cards.user_with_answers_percentage.label}</div>
                        <div className="number">{cards.user_with_answers_percentage.value}</div>
                    </div>
                    <div className="card">
                        <div className="subhead">{cards.user_with_three_answers.label}</div>
                        <div className="number">{cards.user_with_three_answers.value}</div>
                    </div>
                    <div className="card">
                        <div className="subhead">{cards.user_with_three_answers_percentage.label}</div>
                        <div className="number">{cards.user_with_three_answers_percentage.value}</div>
                    </div>
                    <div className="card">
                        <div className="subhead">{cards.user_with_five_answers.label}</div>
                        <div className="number">{cards.user_with_five_answers.value}</div>
                    </div>
                </div>
            </div>
            <div className="stat_content">
                <div className="card_main">
                    <div className="card">
                        <div className="subhead">{cards.user_with_five_answers_percentage.label}</div>
                        <div className="number">{cards.user_with_five_answers_percentage.value}</div>
                    </div>
                    <div className="card">
                        <div className="subhead">{cards.avg_answers_per_user.label}</div>
                        <div className="number">{cards.avg_answers_per_user.value}</div>
                    </div>
                </div>
            </div>
            <div className="stat_content">
                <h3>Sessions</h3>
                <div className="card_main">
                    <div className="card">
                        <div className="subhead">{cards.three_sessions.label}</div>
                        <div className="number">{cards.three_sessions.value}</div>
                    </div>
                    <div className="card">
                        <div className="subhead">{cards.three_sessions_percentage.label}</div>
                        <div className="number">{cards.three_sessions_percentage.value}</div>
                    </div>
                    <div className="card">
                        <div className="subhead">{cards.avg_two_minutes.label}</div>
                        <div className="number">{cards.avg_two_minutes.value}</div>
                    </div>
                    <div className="card">
                        <div className="subhead">{cards.avg_session_length.label}</div>
                        <div className="number">{cards.avg_session_length.value}</div>
                    </div>
                    <div className="card">
                        <div className="subhead">{cards.three_days.label}</div>
                        <div className="number">{cards.three_days.value}</div>
                    </div>
                </div>
            </div>
            <div className="stat_content">
                <div className="card_main">
                    <div className="card">
                        <div className="subhead">{cards.three_days_percentage.label}</div>
                        <div className="number">{cards.three_days_percentage.value}</div>
                    </div>
                    <div className="card">
                        <div className="subhead">{cards.two_minutes.label}</div>
                        <div className="number">{cards.two_minutes.value}</div>
                    </div>
                    <div className="card">
                        <div className="subhead">{cards.two_minutes_percentage.label}</div>
                        <div className="number">{cards.two_minutes_percentage.value}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Statistic
