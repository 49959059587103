import { actionTypes } from "../constants/action-types";

const initialState = []

export const activityReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case actionTypes.SET_ACTIVITIES:
      return [ ...payload ];
    default:
      return state;
  }
};
