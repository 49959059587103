import React from 'react';
import { createStore } from '@react-pdf-viewer/core';

import StoreProps from './StoreProps';

const CustomZoomPlugin = () => {
    const store = React.useMemo(() => createStore({}), []);

    return {
        install: (pluginFunctions) => {
            store.update('zoom', pluginFunctions.zoom);
        },
        zoomTo: (scale) => {
            const zoom = store.get('zoom');
            if (zoom) {
                // Zoom to that scale
                zoom(scale);
            }
        },
    };
};

export default CustomZoomPlugin;
