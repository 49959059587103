import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GrNext, GrPrevious } from "react-icons/gr";
import Filter from "../../Components/Filter/Filter";
import "./Carousel.scss";
import { useDispatch, useSelector } from "react-redux";
import { masterRequest, reviewFlashcard } from "../../helpers/requests";
import { Link } from "react-router-dom";
import { getUserAuthToken, getApiUrl } from "../../helpers/requests";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";

const CarouselNew = () => {
  const dispatch = useDispatch();
  const flashcards = useSelector((state) => state.master.questions.flashcard);
  const [flashcard, setFlashcard] = useState({
    total: 0,
    reviewed: 0,
    questions: [],
    attempted: [],
  });
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [fileIds, setFileIds] = useState(null);
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();

  //
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [resourcedata, setResourceData] = useState([]);
  const [quizdata, setQuizData] = useState([]);
  const [indexofresource, setIndexOfResurce] = useState(0);

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question == selectedQuestion ? null : question);
    // reviewFlashcard({
    //   dispatch,
    //   data: {
    //     question_id: question.id,
    //     answer: "Reviewed",
    //     answer_text: "Reviewed",
    //     type: question.type,
    //     draft: null,
    //     status: 1,
    //   },
    // });
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <GrNext />,
    prevArrow: <GrPrevious />,
    centerPadding: "200px",
  };

  useEffect(() => {
    if (flashcards) {
      setFlashcard({ ...flashcards });
    }
  }, [flashcards]);

  useEffect(() => {
    masterRequest({ dispatch });
  }, []);

  useEffect(() => {
    let sortedQuestions = { ...flashcards };

    if (fileIds) {
      sortedQuestions.questions = sortedQuestions?.questions?.filter(
        (question) => fileIds.includes(question.content_id)
      );
      sortedQuestions.attempted = sortedQuestions?.attempted?.filter(
        (question) => fileIds.includes(question.content_id)
      );
    }
    sortedQuestions.total =
      sortedQuestions?.questions.length + sortedQuestions?.attempted.length;
    sortedQuestions.reviewed = sortedQuestions?.attempted.length;

    setFlashcard({ ...sortedQuestions });
  }, [fileIds]);

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    fetch(`${apiUrl}/all-resource`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          setIndexOfResurce(0);
          setResourceData(response.data);
        }
      });
  }, []);

  useEffect(() => {
    if (resourcedata.length) {
      setLoaderStatus(true);
      var re_id =
        resourcedata[indexofresource ? indexofresource : 0].resource_id;
      var credentials = {
        resource_id: re_id,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(credentials),
      };
      fetch(`${apiUrl}/flashcard`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.status) {
            setLoaderStatus(false);
            setQuizData(response.data);
          }
        });
    }
  }, [indexofresource, resourcedata]);

  const handleUpdate = (item, index) => {
    setIndexOfResurce(index)
  };

  return (
    <div className="main_carousel">
      {quizdata.length == 0 ? (
        <>
          <Filter
            setFileIds={(e) => setFileIds(e)}
            resource={resourcedata}
            indexOfItem={indexofresource}
          />

          <div className="card">
            <p className="title" style={{ fontSize: "20px" }}>
              There are no flashcards available at the moment. Please create
              some by adding your
              <Link style={{ textDecoration: "none" }} to="/uploadfile">
                {" "}
                papers
              </Link>
            </p>
          </div>
        </>
      ) : (
        <>
          <Filter
            findItem={handleUpdate}
            setFileIds={(e) => setFileIds(e)}
            resource={resourcedata}
            indexOfItem={indexofresource}
          />
          <div className="carousel_content">
            <Slider {...settings}>
              {quizdata.map((question, index) => {
                return (
                  <div
                    className="card"
                    key={"card_" + index}
                    onClick={() => handleQuestionClick(question.question_id)}
                  >
                    {selectedQuestion !== question.question_id ? (
                      <h2 className="title">
                        {question.question_content.question}
                      </h2>
                    ) : null}
                    {selectedQuestion === question.question_id && (
                      <>
                        {question.question_content.options.map((item) => (
                          <>
                            {item.is_correct ? (
                              <div className="para">{item.text}</div>
                            ) : null}
                          </>
                        ))}
                      </>
                    )}
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="carousel_mobile">
            <Slider {...settings}>
              {quizdata.map((question, index) => (
                <div
                  className="card_mobile"
                  key={"mobile_card_" + index}
                  onClick={() => handleQuestionClick(question.question_id)}
                >
                  <h2 className="title">
                    {question.question_content.question}
                  </h2>
                  {selectedQuestion === question.question_id && (
                    <>
                      {question.question_content.options.map((item) => (
                        <>
                          {item.is_correct ? (
                            <div className="para">{item.text}</div>
                          ) : null}
                        </>
                      ))}
                    </>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        </>
      )}
      <LoaderSpinner data={loaderStatus}></LoaderSpinner>
      {/* {flashcard?.total == 0 && flashcard?.reviewed == 0 ? (
        <>
          <Filter setFileIds={(e) => setFileIds(e)} />

          <div className="card">
            <p className="title" style={{ fontSize: "20px" }}>
              There are no flashcards available at the moment. Please create
              some by adding your
              <Link style={{ textDecoration: "none" }} to="/material">
                {" "}
                papers
              </Link>
            </p>
          </div>
        </>
      ) : flashcard?.total > 0 && flashcard?.questions.length == 0 ? (
        <>
          <Filter setFileIds={(e) => setFileIds(e)} />
          <div className="carousel_content">
            <Slider {...settings}>
              {flashcard?.attempted?.map((question, index) => {
                return (
                  <div
                    className="card"
                    key={"card_" + index}
                    onClick={() => handleQuestionClick(question)}
                  >
                    {selectedQuestion !== question.id ? (
                      <h2 className="title">{question.statement}</h2>
                    ) : null}
                    {selectedQuestion === question.id && (
                      <div className="para">{question.answer}</div>
                    )}
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="carousel_mobile">
            <Slider {...settings}>
              {flashcard?.attempted?.map((question, index) => (
                <div
                  className="card_mobile"
                  key={"mobile_card_" + index}
                  onClick={() => handleQuestionClick(question)}
                >
                  <h2 className="title">{question.statement}</h2>
                  {selectedQuestion === question.id && (
                    <div className="para">{question.answer}</div>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        </>
      ) : (
        <>
          <Filter setFileIds={(e) => setFileIds(e)} />
          <div className="carousel_content">
            <Slider {...settings}>
              {flashcard?.questions?.map((question, index) => {
                return (
                  <div
                    className="card"
                    key={"card_" + index}
                    onClick={() => handleQuestionClick(question)}
                  >
                    {selectedQuestion !== question.id ? (
                      <h2 className="title">{question.statement}</h2>
                    ) : null}
                    {selectedQuestion === question.id && (
                      <div className="para">{question.answer}</div>
                    )}
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="carousel_mobile">
            <Slider {...settings}>
              {flashcard?.questions?.map((question, index) => (
                <div
                  className="card_mobile"
                  key={"mobile_card_" + index}
                  onClick={() => handleQuestionClick(question)}
                >
                  <h2 className="title">{question.statement}</h2>
                  {selectedQuestion === question.id && (
                    <div className="para">{question.answer}</div>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        </>
      )} */}
    </div>
    // <div className="main_carousel">
    //   {flashcard?.total == 0 && flashcard?.reviewed == 0 ? (
    //     <>
    //       <Filter setFileIds={(e) => setFileIds(e)} />

    //       <div className="card">
    //         <p className="title" style={{ fontSize: "20px" }}>
    //           There are no flashcards available at the moment. Please create
    //           some by adding your
    //           <Link style={{ textDecoration: "none" }} to="/material">
    //             {" "}
    //             papers
    //           </Link>
    //         </p>
    //       </div>
    //     </>
    //   ) : flashcard?.total > 0 && flashcard?.questions.length == 0 ? (
    //     <>
    //       <Filter setFileIds={(e) => setFileIds(e)} />
    //       <div className="carousel_content">
    //         <Slider {...settings}>
    //           {flashcard?.attempted?.map((question, index) => {
    //             return (
    //               <div
    //                 className="card"
    //                 key={"card_" + index}
    //                 onClick={() => handleQuestionClick(question)}
    //               >
    //                 {selectedQuestion !== question.id ? (
    //                   <h2 className="title">{question.statement}</h2>
    //                 ) : null}
    //                 {selectedQuestion === question.id && (
    //                   <div className="para">{question.answer}</div>
    //                 )}
    //               </div>
    //             );
    //           })}
    //         </Slider>
    //       </div>
    //       <div className="carousel_mobile">
    //         <Slider {...settings}>
    //           {flashcard?.attempted?.map((question, index) => (
    //             <div
    //               className="card_mobile"
    //               key={"mobile_card_" + index}
    //               onClick={() => handleQuestionClick(question)}
    //             >
    //               <h2 className="title">{question.statement}</h2>
    //               {selectedQuestion === question.id && (
    //                 <div className="para">{question.answer}</div>
    //               )}
    //             </div>
    //           ))}
    //         </Slider>
    //       </div>
    //     </>
    //   ) : (
    //     <>
    //       <Filter setFileIds={(e) => setFileIds(e)} />
    //       <div className="carousel_content">
    //         <Slider {...settings}>
    //           {flashcard?.questions?.map((question, index) => {
    //             return (
    //               <div
    //                 className="card"
    //                 key={"card_" + index}
    //                 onClick={() => handleQuestionClick(question)}
    //               >
    //                 {selectedQuestion !== question.id ? (
    //                   <h2 className="title">{question.statement}</h2>
    //                 ) : null}
    //                 {selectedQuestion === question.id && (
    //                   <div className="para">{question.answer}</div>
    //                 )}
    //               </div>
    //             );
    //           })}
    //         </Slider>
    //       </div>
    //       <div className="carousel_mobile">
    //         <Slider {...settings}>
    //           {flashcard?.questions?.map((question, index) => (
    //             <div
    //               className="card_mobile"
    //               key={"mobile_card_" + index}
    //               onClick={() => handleQuestionClick(question)}
    //             >
    //               <h2 className="title">{question.statement}</h2>
    //               {selectedQuestion === question.id && (
    //                 <div className="para">{question.answer}</div>
    //               )}
    //             </div>
    //           ))}
    //         </Slider>
    //       </div>
    //     </>
    //   )}
    // </div>
  );
};

export default CarouselNew;
