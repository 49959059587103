import React from 'react'
import { BsEnvelopeCheck } from 'react-icons/bs';
import { RxCross1 } from 'react-icons/rx';
import { Link } from 'react-router-dom';
import './Message.scss'



const Message = () => {


    return (
        <div className='main_message'>
            <div className="message_content">
                {/* <div className="close">
                    <RxCross1 />
                </div> */}
                <div className="message_material">
                    <div className="icon">
                        <BsEnvelopeCheck />
                    </div>
                    <h5 className="title">
                        Thanks for your message!
                    </h5>
                    <p className="para">
                        We do our best to reply
                        in few hours.
                    </p>
                    <p className="para">
                        Dende is growing and so he's customer care team!
                    </p>
                    <Link style={{textDecoration:'none'}} to='/aloha'>
                    <button className='button' style={{ marginTop: '50px' }}>Back to home</button>
                    </Link>
                        
                </div>
            </div>
        </div>
    )
}

export default Message
