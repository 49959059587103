// import "./PushMode.scss";
import style from "./PushMode.module.scss";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { getUserAuthToken, getApiUrl,paypalUIWebhook, paypalInfo, GetUserInfoAPI } from "../../helpers/requests";
import { useNavigate } from "react-router-dom";
import tickIcon from "../../assets/tick_with_round.png"
import Sidebar from "../../Components/Sidebar/Sidebar";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import LoadingGif from "../../assets/loading-svg.svg"
import supported_payment_provider from "../../assets/supported_payment_providers.svg"
import stripe_btn_image from "../../assets/stripe-btn-image.svg"
import ReactPixel from 'react-facebook-pixel';

export default function PushMode(){
    var token = getUserAuthToken();
    const navigate = useNavigate();
    var apiUrl = getApiUrl();
    var user_id = localStorage.getItem("userId");
    const [loader_values, ser_loader_values] = useState(false);
    const [pay_btn_popup, set_pay_btn_popup] = useState(false);
    const [sub_plan_type, set_sub_plan_type] = useState("1_month");
    const sub_plan_type_ref = useRef("1_month")
    const stripe_pay_btn_element = useRef(null);
    const paypal_btn = useRef(null);
    const stripe_form_element = useRef(null);


    useEffect(() => {
        // facebook
        ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
        const urlParams = new URLSearchParams(window.location.search);
        const paymentStatus = urlParams.get('paymentstatus');
        if(paymentStatus === "false"){
            toast.error("Payment failed!", {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        // senja user feedback UI embedded code
        let headElement = document.getElementsByTagName('head')[0];
        let scriptElement = document.createElement('script');
        scriptElement.async = 1;
        scriptElement.src = "https://static.senja.io/dist/platform.js";
        scriptElement.type = "text/javascript";
        headElement.appendChild(scriptElement);

        // // if sub_plan_type is 3_month in url params
        // if (urlParams.get("sub_plan_type") !== null) {
        //     if(urlParams.get("sub_plan_type") === "3_month"){
        //         set_sub_plan_type("3_month")
        //     }
        // }
    }, []);


    // when user clicks on paypal button, new subscription is initiated
    const createSubscriptionFun = (data, actions) => {
        return actions.subscription.create({
            'plan_id': sub_plan_type_ref.current === "1_month" ? process.env.REACT_APP_PAYPAL_ONE_MONTH_PLAN_ID : process.env.REACT_APP_PAYPAL_THREE_MONTH_PLAN_ID,
            'custom_id': localStorage.getItem("userId")
        })
        .then((res) => {
            return res;
        });
    };

    // paypal: after successfully payment, this function is called also subscription details are passed
    const onSubscriptionApprove = (data, actions) => {
        let subscription_id = data["subscriptionID"]
        let body = {"subscription_id": subscription_id}
        // subscription details stored on the DB
        paypalUIWebhook(body);
        set_pay_btn_popup(false);
        ser_loader_values(true);
        let intervalId = setInterval(async ()=>{
            let response = await paypalInfo();
            if(response["data"]["status"] !== "free" && response["data"]["status"] !== "" ){
                let userinfo_response =  await GetUserInfoAPI();
                localStorage.setItem("plan_type", userinfo_response.data[0].plan_type);
                localStorage.setItem("plan_end_date", userinfo_response.data[0].plan_end_date);
                clearInterval(intervalId);
                ser_loader_values(false);
                navigate("/home?paymentstatus=true&plan_name=30_days&plan_value=6.90");
            }
        },5000);
    };


    async function checkUserPaymentStatus(){
        // facebook
        let user_id = localStorage.getItem("userId");
        ReactPixel.trackCustom('Initiated checkout', { "content_ids": [user_id] });        
        let response =  await GetUserInfoAPI();

        handleMarketingData(response)
        if(response.data[0].plan_type === "free" || response.data[0].subscription_status === "cancelled"){

            set_pay_btn_popup(true);
        } else {
            toast.error("Your have already have an active plan!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: true,
            });
        }
    }


    function handleMarketingData(apiResponse){
        try{
            let temp_user_id = String(localStorage.getItem("userId")) || "";
            let temp_plan_name = sub_plan_type_ref.current;
            let temp_plan_price = temp_plan_name === "30_days" ? 6.9 : temp_plan_name === "3_months" ? 17.40 : "" ;        
            // google tag manager
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                event: "begin_checkout",
                ecommerce: {
                    currency: "EUR",
                    value: temp_plan_price,
                    items: [
                        {
                            item_id: temp_plan_name,
                            item_name: temp_plan_name,
                            price: temp_plan_price,
                            quantity: 1,
                            user_id: temp_user_id,
                        }
                    ]
                }
            });            
        } catch(error) {
            console.error('pushmode page', error)
        }    
    }    


    return(
        <>
            <ToastContainer />
            <section style={loader_values ?  {"display":"block"} : {"display":"none"}}
                className={style["pushmode-loading-container"]}>
                <div className={style["pushmode-loading-content"]}>
                    <div>
                        <img src={LoadingGif} alt="loading gif" />
                    </div>
                    <p>Fetching payment info...</p>
                </div>
            </section>
            <main className={style["push-mode-container"]}>
                <div className={style["push-mode-content"]}>
                    <div className={style["title-section"]}>
                        <h3>Time to push? 👊</h3>
                        <div className={style["push-mode-bullet-container"]}>
                            <article className={style["push-mode-bullet"]}>
                                <img src={tickIcon} alt="tick" />
                                <p>Unlimited files upload</p>
                            </article>
                            <article className={style["push-mode-bullet"]}>
                                <img src={tickIcon} alt="tick" />
                                <p>Access all questions and answers</p>
                            </article>
                            <article className={style["push-mode-bullet"]}>
                                <img src={tickIcon} alt="tick" />
                                <p>Discover all concepts</p>
                            </article>
                        </div>  
                    </div>
                    <section className={style["plans-section"]}>
                        <div className={style["plans-section-left"]}>
                            {/* subscription type toggle button */}
                            <div className={style["toggle-btn-container"]}>
                                <div className={`${style["toggle-btn-content"]}`}>
                                    <div 
                                        className={ sub_plan_type === "1_month" ? `${style["toggle-btn-selected"]} ${style["toggle-btn"]}` : style["toggle-btn"]}
                                        onClick={()=>{set_sub_plan_type("1_month"); sub_plan_type_ref.current = "1_month"}}
                                    >
                                        Monthly
                                    </div>
                                    <div 
                                        className={ sub_plan_type === "3_month" ? `${style["toggle-btn-selected"]} ${style["toggle-btn"]}` : style["toggle-btn"]}
                                        onClick={()=>{set_sub_plan_type("3_month"); sub_plan_type_ref.current = "3_month"}}
                                    >
                                        3 months
                                        <span>-15%</span>
                                    </div>
                                </div>
                            </div>
                            {/* subscription info card */}
                            <div className={style["plan-card"]}>
                                {/* offer banner */}
                                <div 
                                    className={style["offer-banner-green"]}
                                    style={sub_plan_type === "3_month"? {display: "block"} : {display: "none"}}
                                >
                                    Save money. Transform your study routine.
                                </div>
                                <div 
                                    className={style["offer-banner-white"]}
                                    style={sub_plan_type === "3_month"? {display: "block"} : {display: "none"}}
                                >
                                </div>

                                <h2 style={sub_plan_type === "1_month"? {display: "block"} : {display: "none"}}>€6,90<span> /month</span></h2>
                                <h2 style={sub_plan_type === "3_month"? {display: "block"} : {display: "none"}}>€5,80<span> /month</span></h2>
                                <p>You can cancel anytime in 1 click</p>
                                <button
                                    onClick={()=>{checkUserPaymentStatus(); handleMarketingData()}}
                                    type="button">
                                    Go unlimited
                                </button>
                                <img src={supported_payment_provider} 
                                    style={{height:"auto", width:"100%", marginTop: "20px"}} alt="images"/>
                            </div>
                        </div>
                        <div className={style["plans-section-right-review"]}>
                            <div class="senja-embed" data-id="779196ac-97b5-4ff0-85c1-3eb32fe959b7" data-lazyload="false"></div>
                        </div>
                    </section>
                    <section 
                        style={pay_btn_popup? {display: "flex"} : {display: "none"}}
                        onClick={(e)=>{
                            if(e.target.className === style["pushmode-popup-backdrop"]){
                                set_pay_btn_popup(false);
                            }
                        }}
                        className={style["pushmode-popup-backdrop"]}>
                        <div className={style["pushmode-popup-content"]}>
                            <h3>How would you like to pay?</h3>
                            <div className={style["pushmode-stripe-btn"]}>
                                <form ref={stripe_form_element} action={`${apiUrl}/subscription/stripe`} method="POST">
                                    <input type="hidden" name="Authorization" value={`Bearer ${token}`} />
                                    <input
                                        type="hidden"
                                        name="lookup_key" 
                                        value={sub_plan_type === "1_month" 
                                        ? 
                                            process.env.REACT_APP_STRIPE_LOOKUP_KEY_ONE_MONTH
                                        : 
                                            process.env.REACT_APP_STRIPE_LOOKUP_KEY_THREE_MONTH}
                                    />
                                    <button
                                        onClick={()=>{
                                            stripe_form_element.current.submit();
                                        }} 
                                        style={{backgroundColor:"#f0f5ff00"}}
                                        id="push_mode_pay" type="button">
                                        <img src={stripe_btn_image} 
                                            style={{width:"115%", height:"auto"}} alt="images"/>
                                    </button>
                                </form>
                            </div>
                            <div className={style["pushmode-paypal-btn"]}>
                                <PayPalScriptProvider options={{ 
                                    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                    "disableFunding": ['card','credit','paylater','bancontact', 'blik', 'eps', 'giropay', 'ideal', 'mercadopago', 'mybank'],
                                    "intent": "subscription",
                                    "vault":true,
                                }}>
                                    <PayPalButtons
                                        style={{ 
                                            layout: "vertical",
                                            color: "white"
                                        }}
                                        createSubscription={createSubscriptionFun}
                                        onApprove={onSubscriptionApprove}
                                    />
                                </PayPalScriptProvider>
                                
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    )
}