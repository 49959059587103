import React, { useState } from 'react'
import { BiMessageSquare } from 'react-icons/bi';
import { CiMail } from 'react-icons/ci';
import { BsEnvelopeCheck } from 'react-icons/bs';
import { RxCross1 } from 'react-icons/rx';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import './Help.scss'


const Help = () => {

    const [showmail, setshowMail] = useState(false)
    const navigate = useNavigate()
    const mailModal = () => {
        setshowMail(true)
    }
    const mailModalclose = () => {
        setshowMail(false)
    }

    const [sendval, setSendval] = useState({
        name: '',
        email: '',
        message: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setSendval((preval) => ({
            ...preval,
            [name]: value,
        }));
    }


    const oncheck = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    const onSend = () => {
navigate('/message')
    }



    return (
        <div className='main_help'>
            <div className="main_boxshadow" style={{ display: showmail == false ? "none" : "flex" }}>
                <div className='main_send'>
                    <div className="message_content">
                        <div className="close" onClick={mailModalclose}>
                            <RxCross1 />
                        </div>
                        <div className="message_material">

                            <h5 className="title">
                                Send us an email
                            </h5>
                            <div className="feed_form">
                                <input value={sendval.name} onChange={handleChange} name='name' className='feed_input' type={'text'} placeholder='Your Name' />
                                <input value={sendval.email} onChange={handleChange} name='email' className='feed_input' type={'text'} placeholder=' Your E-mail (we reply here)' />
                                <textarea
                                    value={sendval.message}
                                    onChange={handleChange}
                                    name='message'
                                    cols={18}
                                    rows={13}
                                    placeholder='Write your message here..'
                                />
                            </div>
                            <Checkbox style={{ marginTop: '20px' }} onChange={oncheck}>I accept <Link to={'/privacy'}>Privacy Policy</Link> and <Link to={'/terms'}>T&C</Link></Checkbox>
                            <Link style={{ textDecoration: 'none', margin: '20px auto' }} to='/message'>
                                <button className='button'>Send email</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="help_content">
                <div className="title" >
                    Here to help you!
                </div>
                {/* <div className="card" style={{cursor: 'pointer'}}>
                    <div className="icon"><BiMessageSquare /></div>
                    <h4 className="text">Chat with us</h4>
                </div> */}


                <div className="card" onClick={mailModal}>
                    <div className="icon"><CiMail /></div>
                    <h4 className="text">Send us an email</h4>
                </div>


            </div>
        </div>
    )
}

export default Help
