import {actionTypes} from '../constants/action-types'

export const setUser = (payload) => {
    return {
        type: actionTypes.SET_USER,
        payload: payload
    }
}

export const setAccount = (payload) => {
    return {
        type: actionTypes.SET_ACCOUNT,
        payload: payload
    }
}

export const setUsers = (payload) => {
    return {
        type: actionTypes.SET_USERS,
        payload: payload
    }
}

export const setActivities = (payload) => {
    return {
        type: actionTypes.SET_ACTIVITIES,
        payload: payload
    }
}
