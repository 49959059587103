import style from "./LoginTopBar.module.css"
import { Link, useLocation, useNavigate } from "react-router-dom";
import dendeLogo from "../../assets/dende-new-logo.png"


export function LoginTopBar() { 
  const location = useLocation();
  const navigate = useNavigate();

    return ( 
      <>
        <div className={style["container"]}>
          <div className={style["content"]}>
            <img src={dendeLogo} alt="dende logo" />
            <p 
              style={location.pathname === "/login" ? {display: "block"} : {display:"none"}}
              onClick={()=>{navigate("/signup")}}
              >
              Signup
            </p>
            <p
              style={location.pathname === "/signup" ? {display: "block"} : {display:"none"}}
              onClick={(e)=>{navigate("/login")}}
              >
              Login
            </p>
          </div>
        </div>
      </>
    ); 
} 
