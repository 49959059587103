import React from 'react'
import Hero from './Components/Hero/Hero'
import Footer from '../../Components/Footer/Footer'
import './Home.scss'
import Students from './Components/Students/Students'
const Home = () => {
  return (
    <div className='main_home'>
      <Hero/>
      {/* <Students/> */}
     <Footer/>
    </div>
  )
}

export default Home
