import React, { useEffect, useState } from 'react'
import { Checkbox } from 'antd'
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import './UserDetail.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getAccount } from '../../helpers/requests'
import { useParams } from 'react-router'
const UserDetail = () => {
    const dispatch = useDispatch();
    const account = useSelector(state => state.account);
    const [eye, seteye] = useState(false)
    const [user, setUser] = useState(null)
    const {id} = useParams();
    
    useEffect(()=>{
        setUser(account);
    }, [account])

    useEffect(()=>{
        getAccount({dispatch, id: id})
    }, [])

    return (
        <div className='userDetail_main'>
            <h1 className="title">User Details</h1>
            {
                user &&
                <>
                    <div className="userdetail_content">
                        <div className="detail_left">
                            <div className="login_input">
                                <input className='feed_input' value={user.name} name='name' type={'text'} placeholder='Name' disabled={true}/>
                                <input className='feed_input' value={user.surname} name='surname' type={'text'} placeholder='Surname' disabled={true}/>
                                <input className='feed_input' value={user.email} name='email' type={'text'} placeholder='Your Email' disabled={true}/>
                                <input className='feed_input' value={user.role} name='role' type={'text'} placeholder='Your Role' disabled={true}/>
                            </div>
                        </div>
                        <div className="line">

                        </div>
                        <div className="detail_right">
                            <div className="feed_form">
                                <input name='exam_language' value={user.exam_language} className='feed_input' type={'text'} placeholder='Language of your exam' disabled={true}/>
                                <input name='study_level' value={user.study_level} className='feed_input' type={'text'} placeholder='Level of studying' disabled={true}/>
                                <input name='study_field' value={user.study_field} className='feed_input' type={'text'} placeholder='Studying field' disabled={true}/>
                                { user.accepted_newfeatures ? <p>Opted to like new features</p> : <p>Did not opted to like new features</p> }
                                { user.accepted_marketing ? <p>Interested in marketing updates</p> : <p>Not interested in marketing updates</p>}
                            </div>
                        </div>


                    </div>
                    {/* <button className='button'>Update</button> */}
                </>
            }
        </div>
    )
}

export default UserDetail
