import React, { useEffect } from 'react'
import emoji from '../../assets/smile.gif'
import { Link, useNavigate } from 'react-router-dom'
import './Aloha.scss'
import { useDispatch, useSelector } from 'react-redux'
import { masterRequest } from '../../helpers/requests'
const Aloha = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const questions = useSelector(state => state.master.questions);
    const contents = useSelector(state => state.master.contents);
    const user = useSelector(state => state.user.data);

    if(user.role == "super_admin"){
        navigate('/statistics')
    }
    const cards = [
        {
            subhead: 'Questions generated',
            number: questions?questions.MCQ.total + questions.open.total: 0
        },
        {
            subhead: 'Questions completed',
            number: questions?questions.MCQ.reviewed + questions.open.reviewed: 0
        },
        {
            subhead: 'Flashcards reviewed',
            number: questions?questions.flashcard.reviewed+"/"+questions.flashcard.total: 0
        },
    ]

    useEffect(()=>{
        masterRequest({dispatch});
    }, [])
    return (
        <>
        { user && user.role === 'user' &&
            <div className='aloha_main'>
                <div className="aloha_content">
                    <div className="title">Aloha {user?.name}! <img src={emoji} alt='Img_Error' /> </div>
                    <div className="aloha_right">
                        <div className="select_aloha">
                            <Link style={{ textDecoration: 'none' }} to='/mcq' >
                                <div className="select">Quiz</div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to='/library'>
                                <div className="select">Library</div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to='/material' >
                                <div className="select">Papers</div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to='/carousel'>
                                <div className="select">Flashcards</div>
                            </Link>
                            <Link style={{ textDecoration: 'none' }} to='/chapters' >
                                <div className="select">Chapters</div>
                            </Link>
                        </div>
                        <div className="card_main">
                            {cards.map((e, index) => {
                                return (
                                    <div className="card" key={"card_"+index}>
                                        <div className="subhead">{e.subhead}</div>
                                        <div className="number">{e.number}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}

export default Aloha
