import React, { useEffect, useState } from 'react'
import {Table} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getActivity } from '../../helpers/requests';


const UserActivity = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const activities = useSelector(state => state.activity);
    const [data, setData] = useState([]);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Time',
            dataIndex: 'created_at',
            key: 'created_at',
        },  
    ];
    

    useEffect(()=>{
        const raw = activities.map((activity, index)=>{
            return {
                id: activity.id,
                key: index + 1,
                type: activity.type,
                description: activity.description,
                created_at: new Date(activity.created_at).toLocaleString(), 
            }
        });
        setData(raw)
    }, [activities])

    useEffect(()=>{
        getActivity({dispatch, id})
    }, [])


    
    return (
        <div className='main_users'>
              <h1 className="title">User Activities</h1>
            <div className="user_content">
                <Table bordered columns={columns} dataSource={data} />;
            </div>
        </div>
    )
}

export default UserActivity
