import React from 'react'
import { BiMessageSquare } from 'react-icons/bi';
import { CiMail } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import './Privacy.scss'


const Privacy = () => {
    return (
        <div className='main_privacy'>
            <div className="privacy_text">
                <div className="heading">Dende Privacy Policy</div>
                <div className="para">Effective Date: [16th of May 2023]</div>
            </div>

            <div className="privacy_text">
                <div className="subheading">A small note on how Dende works.</div>
            </div>

            <ul className="main_ul">
                <li>Users upload Contents on Dende.</li>
                <li>The Contents are sent for the purposes of prompting (input prompts) to an AI model to
                    perform a Task such, for example, the creation of flash cards tailored to the specific
                    Contents.</li>
                <li>These Tasks can be performed by a variety of third-party AI models and services that we
                    integrate with.</li>
                <li>After the AI services have completed their Task, the results (output prompts) are shared
                    back with the User.</li>
                <li>After the Tasks have been completed, all the prompts (both input and output) are stored
                    on our servers and we might use these prompts (input and output) to train and/or create
                    our artificial intelligence/products.</li>
                <li>We do not use your Personal Information (as defined in our Privacy Policy) to generally
                    train and/or create our separate artificial intelligence/products.</li>
            </ul>

            <div className="privacy_text">
                <div className="para">We encourage you to get acquainted with our Terms of Use to understand how we provide
                    services to you. We respect your privacy and are committed to protecting it through our
                    compliance with this Policy (as defined below). Please read this Policy carefully to understand
                    our privacy practices. If you do not want us to process your Personal Information as it is
                    described in this Policy, please do not use our Services (as defined below). If you have any
                    questions about this Policy or Dende, please contact us at privacy@dende.ai (for additional
                    contact information, please, see Section 16 of this Policy.</div>
            </div>

            <div className="privacy_text">
                <div className="heading">1. Introduction</div>
                <div className="para">
                    Unilancer di Alec Conti (“Company”, “we”, “us” or “our”) is a technology company specialising
                    in artificial intelligence related products. This Privacy Policy (“Policy”) covers the Personal
                    Information (as defined below) that we collect through Dende application and its website
                    www.dende.ai (the “Website”), owned or controlled by the Company or websites that post a link
                    to this Policy (the “Services”).
                </div>
                <div className="para">
                    When you use our Services, you’ll share some information with us. According to Article 13 of
                    the General Data Protection Regulations Regulation, (EU) 2016/679 (GDPR), in our role as a
                    controller of Personal Information (as defined below), we want to be upfront about the
                    information we collect, how we use it, whom we share it with, and the controls we give you to
                    access, update, and delete your information.
                </div>
                <div className="para">
                    You should read our entire Policy, so that you can feel confident in sharing your data with us. In
                    this Policy, personal information means information that (either in isolation or in combination
                    with other information) enables you to be directly or indirectly identified (“Personal
                    Information”).
                </div>
            </div>
            <div className="privacy_text">
                <div className="heading">2. Who is the data controller</div>
                <div className="para">
                    The Company is the controller of your Personal Information. The Company is registered at Via
                    Liberta’ 9, Concorezzo, MB, Italy If you have questions, requests, or concerns regarding your
                    privacy and rights, please let us know how we can help. You can contact us at privacy@dende.ai.
                </div>
            </div>


            <div className="privacy_text">
                <div className="heading">3. Information we collect</div>
                <div className="para">
                    There are three basic categories of information we collect:<br />
                    <strong>● Information you provide to us</strong><br />
                    <strong>● Information we collect automatically</strong><br />
                    <strong>● Information we obtain from third parties</strong><br />
                    Here’s a little more detail on each of these categories.
                </div>
                <div className="para">
                    <strong>Information you provide to us</strong><br />
                    When you use our Services, you usually provide to us certain information, including your
                    Personal Information such as:
                </div>


                <div className="para">
                    <strong>Information you provide to us</strong>:  When you use our Services, you usually provide to us certain information, including your
                    Personal Information such as:
                </div>

                <div className="para">
                    <strong>● Identification information:</strong>
                    such as your name, surname, date of birth, email, IP and
                    user location, Device ID
                </div>


                <div className="para">
                    ● If you contact us, records, copies of your correspondence with us and contact details that
                    you have provided us while making your inquiries (such as your name, postal
                    addresses, email addresses and phone numbers or any other identifier by which you may
                    be contacted online or offline), in order to investigate your concerns, respond to your
                    inquiries and to monitor and improve our responses to your and other users inquiries in
                    relation to our Services.
                </div>
                <div className="para">
                    <strong>When you use the Websites</strong>:
                    When you visit our Website, we collect Internet or other electronic network activity
                    information through the use of cookies and other trackers. Depending on your tracking
                    preferences, the information we collect may include but is not limited to your device’s
                    Internet Protocol (“IP’) address, referring website, what pages your device visited, and the
                    time that your device visited our Website. We may also rely on analytics and tools used to
                    prevent spam and other security risks related to the use of abusive automated software. Visit
                    our Cookie Policy for more information on the types of cookies and other trackers we use on
                    our Website.
                </div>
                <div className="para">
                    <strong>Information we collect automatically</strong><br />
                    When you use our Services, we may automatically collect certain data about you. This data is
                    needed for operation of the Services and, among others, may be used for in-app analytics or
                    marketing purposes. The data we collect automatically includes:
                </div>
                <div className="para">
                    <strong>● Device information:</strong> Information about your mobile device and internet connection,
                    including your IP address, the device’s unique device identifier, operating system, and
                    mobile network information.
                </div>
                <div className="para">
                    <strong>● Usage details:</strong>  Details of your use of our Services, including frequency of use, areas and
                    features of the application that you access and engagement with particular features. This
                    information is used for in-app event analytics and, upon your consent, for marketing
                    purposes.
                </div>
                <div className="para">
                    <strong>● Information collected by cookies and other similar technologies:</strong>  we use various
                    technologies to collect information, including saving cookies to users’ computers.
                </div>
                <div className="para">
                    Such automatically collected data helps us operate our Services and improve it to deliver better
                    service, including but not limited to enabling us to estimate our audience size, and understand
                    how you use our Services and what you like and dislike the most.
                </div>
                <div className="para">
                    For further information please visit the Cookie Policy.
                </div>
                <div className="para">
                    <strong>The technologies we use for automatic data collection may include:</strong> <br />
                    We use third-party analytics tools, such as Google Analytics, Firebase, Meta, AppsFlyer and
                    Amplitude, Hotjar to help us measure traffic and usage trends for Dende. These tools collect
                    information via third-party analytics software development kits incorporated into Dende, which
                    may include your pseudonymised user ID and the information about the webpages you visit
                    when following the links available to you on our Services, your actions in Dende application and
                    basic information about the type of subscription you have. We collect and use this analytics
                    information in an aggregated manner with analytics information from other users so that it
                    cannot reasonably be used to identify any particular user. For information on how third-party
                    analytics tools collect and process your data and to opt out of such collection and processing,
                    generated by your use of Dende, please contact us at privacy@dende.ai.
                </div>


            </div>




            <div className="privacy_text">
                <div className="heading">4. Legal bases for using your Personal Information</div>
                <div className="para">
                    According to Article 6 of the GDPR, we are allowed to use your Personal Information when
                    certain conditions apply. These conditions are called “legal bases” and we typically rely on one
                    of four:
                </div>
                <div className="para">
                    <strong>● Consent (Article 6.1.a of the GDPR):</strong> in some cases, we’ll ask for consent to use your
                    information for specific purposes. If we do, we’ll make sure you can revoke your consent in
                    our Services or through your device permissions. Even if we’re not relying on consent to use
                    your information, we may ask you for permission to access data like contacts and location.
                </div>
                <div className="para">
                    <strong>● Contract (Article 6.1.b of the GDPR):</strong>  we might use your information because you’ve
                    entered into an agreement with us.
                </div>
                <div className="para">
                    <strong>● Legal obligation (Article 6.1.c of the GDPR):</strong> we may be required to use your personal
                    information to comply with the law, like when we respond to valid legal process or need to
                    act to protect our users.
                </div>
                <div className="para">
                    <strong>● Legitimate interest (Article 6.1.f of the GDPR):</strong> we have—or a third party has—a
                    legitimate interest in doing so. For example, we need to use your information to provide and
                    improve our Services, including protecting your account, providing customer support. An
                    important point to understand about legitimate interest is that our interests don’t outweigh
                    your right to privacy, so we only rely on legitimate interest when we think the way we are
                    using your data doesn’t significantly impact your privacy or would be expected by you, or
                    there is a compelling reason to do so.
                </div>

                <div className="para">
                    <strong>Personal Information we collect directly from you on our Website</strong><br />
                    We will use Personal Information for the following purposes:<br />
                    ● To administer our Website, and for internal operations, including troubleshooting, data
                    analysis, testing, statistical and survey purposes.<br />
                    ● To improve our Website to ensure that content is presented most effectively for you and your
                    device.<br />
                    ● For trend monitoring, marketing advertising, ad targeting, ad measurement, including
                    through the use of your precise location information, profiling, research (again, if you’ve
                    specifically and freely given us permission to use that information for that purpose), both on
                    and off our services. We may also store information about your use of third-party apps and
                    websites on your device to do this.<br />
                    ● For purposes made clear to you at the time you submit your Personal Information.<br />
                    ● As part of our efforts to keep our Websites secure.<br />
                </div>

                <div className="para">
                    Our use of your Personal Information may be based on our legitimate interests to ensure
                    network, information security, and business performance improvement. Our direct marketing
                    purposes are based on your consent. We may also rely on our legitimate interests to improve
                    business and marketing practices or contact you to offer similar Services or products that you
                    may have bought from us (soft spam), requested a demo, or negotiated with us.
                </div>

                <div className='para'>
                    <strong>Personal Information we collect directly from you as part of the administration of our
                        Services</strong>
                </div>

                <div className='para'>We will use Personal Information for the following purposes:</div>

                <div className='para'>
                    ● To carry out our obligations and enforce our rights arising from any contracts entered into
                    between you and us in relation to our Services (e.g., the Terms of Use).<br />
                    ● To provide you with support and to respond to your inquiries. If you share your Personal
                    Information (including your name and contact information) to ask a question about our
                    Services, we will use that Personal Information to investigate your concerns, respond to
                    your inquiries and to monitor and improve our responses to your and other users’
                    inquiries in relation to our Services. It is our legitimate interest to provide you with
                    high-quality support.<br />
                    ● To notify you about changes to our Services or any further product or service we offer or
                    provide through our Services, including by sending you technical notices, notices about
                    your account/subscription, including expiration and renewal notices, updates, security
                    alerts and support and administrative messages, which we may send through an in-app or
                    a push notification (you may opt-out of push notifications by changing the settings on
                    your mobile device). It is our legitimate interest to keep you updated on your use of our
                    Services.<br />
                    ● To monitor aggregated metrics such as total number of users, traffic, and demographic
                    patterns. It is our legitimate interest to make aggregated analytics of our audience as it
                    helps us understand our business metrics and improve our Services.<br />
                    ● To provide, improve, test, and monitor the effectiveness of our Services in an aggregated
                    manner. It is our legitimate interest to make analytics of our audience as it helps us
                    understand our product and business metrics.<br />
                    ● To provide personalized content and information to you in relation to our Services, which
                    could include online ads or other forms of marketing. We do so upon your explicit
                    consent (opt-in).<br />
                    ● Upon users’ consent (opt-in), for marketing purposes in order to find audiences similar to
                    our users. For this processing we share, among others, users’ use details, device
                    information, and in-app purchase details with our third-party advertising partners.<br />
                    ● Cookies: we use strictly necessary cookies and other trackers to provide authentication
                    tools, enhance security, and prevent fraud. For more information about our use of cookies
                    and other trackers visit the Cookie Policy.<br />
                    ● In any other way, as described in this Policy.<br />
                </div>

                <div className="para">
                    We will not process your Personal Information in a way that is incompatible with the purposes
                    for which it has been collected or authorized by you in accordance with this Section 4 or collect
                    any Personal Information that is not required for the mentioned purposes (“purpose limitation
                    principle”). For any new purpose of processing that is not compatible with any of the purposes
                    mentioned above, we will ask for your separate explicit consent. To the extent necessary for any
                    of the processing purposes, we take all reasonable steps to ensure that your Personal Information
                    is reliable for its intended use, accurate, complete and current. We also undertake to collect only
                    such amount and types of your Personal Information that are strictly required for the purposes
                    mentioned in this Section 4 (“data minimization principle”).
                </div>


            </div>


            <div className="privacy_text">
                <div className="heading">5. Sharing and disclosure of Personal Information</div>
                <div className="para">
                    <strong>Parties with whom we may share your Personal Information.</strong>
                    We may share your Personal Information and other collected information with third-party
                    organizations such as contractors and service providers that we use to support our business who
                    will act as data processors according to Article 28 of the GDPR. and who are bound by
                    confidentiality and data protection terms (consistent with this Policy) to keep your Personal
                    Information confidential and use it only based on our instructions (“Service Providers”). If we
                    receive your Personal Information and subsequently transfer that information to a third-party
                    agent or Service Provider for processing, we remain responsible for ensuring that such
                    third-party agent or Service Provider processes your Personal Information to the standard
                    required by the applicable privacy laws, including the GDPR, and CCPA. These transfers will
                    typically be based on our legitimate interests.
                </div>

                <div className="para">
                    Such Service Providers include:
                </div>

                <div className="para">
                    ● Amazon Web Services which we use when you choose to upload your documents to our
                    Services,<br />
                    ● Third-party analytics, as specified in this Policy,<br />
                    ● Email delivery services, and<br />
                    ● Third-party advertising partners. Upon your consent, we send some of your Personal
                    Information (e.g., your device information and details about your in-app purchases) for
                    marketing and promotional purposes to our advertising partners (e.g., Google, AppsFlyer,
                    Meta).<br />
                    ● By these means, we are able to reach you and people like you on various platforms.<br />
                </div>
                <div className="para">
                    For the avoidance of doubt, we do not share or transfer your Contents to third parties (except
                    storing your Contents to our cloud provider Amazon Web Services). We use only secure places
                    of storage as Amazon Web Services.
                </div>
                <div className="para">
                    <strong>What happens in the event of a change of control.</strong> If we sell or otherwise transfer part or the
                    whole of the Company or our assets to another organization (e.g., in the course of a transaction
                    like a merger, divestiture, restructuring, reorganization, acquisition, bankruptcy, dissolution,
                    liquidation), your Personal Information and any other collected information may be among the
                    items sold or transferred.
                </div>
                <div className="para">
                    <strong>Responding to legal requests and preventing harm.</strong> We may access, preserve and share your
                    Personal Information in response to a legal (like a search warrant, court order or subpoena),
                    government or regulatory request if we have a good faith belief that the law requires us to do so.
                    This may include responding to legal, government or regulatory requests from jurisdictions
                    where we have a good faith belief that the response is required by law in that jurisdiction, affects
                    users in that jurisdiction, and is consistent with internationally recognized standards. We may
                    also access, preserve and share information when we have a good faith belief it is necessary to:
                    (i) detect, prevent and address fraud and other illegal activity; (ii) protect ourselves, you and
                    others, including as part of investigations; and (iii) prevent death or imminent bodily harm.
                    Information we receive about you may be accessed, processed and retained for an extended
                    period of time when it is the subject of a legal request or obligation, governmental investigation,
                    or investigations concerning possible violations of our terms or policies, or otherwise to prevent
                    harm.
                </div>
                <div className="para">
                    <strong>Protection of our company and others</strong><br />
                    We reserve the right to access, read, preserve, and disclose any Personal Information as
                    necessary to i) comply with a law or a court order, ii) protect the rights, property, or safety of our
                    employees, our users, or others.
                </div>
                <div className="para">
                    <strong>Non-personal information</strong><br />
                    We may also share with third parties that provide services to us or perform business purposes for
                    us aggregated, non-personally identifiable, or de-identified information.
                </div>
            </div>


            <div className="privacy_text">
                <div className="heading">6. Third-Party Content and Integrations</div>
                <div className="para">
                    Our Services will be based on third-party contents generated by the Models. Hence, will be
                    providing information to the third party as well as to us. We are not responsible for how those
                    third parties collect or use your information. As always, we encourage you to review the privacy
                    policies of every third-party service that you use, including those third parties you interact with
                    through our Services.
                </div>
            </div>
            <div className="privacy_text">
                <div className="heading">7. International data transfer</div>
                <div className="para">
                    Your Personal Information might be processed outside the EU. As to the location of our servers,
                    we use AWS servers located in the EU. However, our third-party AI Service Providers Servers
                    may be located, stored and/or processed outside EEA/UK countries.
                </div>
                <div className="para">
                    When Service Providers engage in such transfers of Personal Information, they rely on i)
                    Adequacy Decisions as adopted by European Commission on the basis of Article 45 of
                    Regulation (EU) 2016/679 (GDPR), or ii) Standard Contractual Clauses issued by the European
                    Commission. The European Commission has determined that the Standard Contractual Clauses
                    provide sufficient safeguards to protect personal data transferred outside the EU or EEA. For
                    more information, please
                    visit https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protecti
                    on/standard-contractual-clauses-scc_en.
                </div>
                <div className="para">
                    We will always protect your Personal Information in accordance with this Policy wherever it is
                    processed. We do not voluntarily or actively transfer or disclose your Personal Information to the
                    government or law enforcement authorities (the “Authorities”) and/or otherwise grant any
                    Authorities access to your Personal Information.
                </div>
            </div>


            <div className="privacy_text">
                <div className="heading">8. Treatment of Personal Information</div>
                <div className="para">
                    We will treat your Personal Information according to correctness, lawfulness and in compliance
                    with all the principles of Article 5 of the GDPR, and with the aid of electronic tools, and in
                    compliance with Article 5 of this Policy.
                    The analysis of your tastes and preferences which, subject to your previous consent, may be
                    carried through profiling, will always involve a human intervention and will never take place
                    exclusively in automated ways.
                </div>
                <div className="para">
                    We use appropriate technical, organizational, and administrative security measures to protect any
                    Personal Information we store from loss, misuse, and unauthorized access, disclosure, alteration,
                    and destruction.
                </div>
                <div className="para">
                    No company or service can guarantee complete security. Unauthorized entry or use, hardware or
                    software failure, and other factors may compromise the security of user’s Personal Information at
                    any time. Among other practices, your account is protected by a password for your privacy and
                    security. You must prevent unauthorized access to your account and Personal Information by
                    selecting and protecting your password appropriately and limiting access to your computer or
                    device and browser by signing off after you have finished accessing your account.
                </div>
            </div>


            <div className="privacy_text">
                <div className="heading">9. Data Retention</div>
                <div className="para">
                    <strong>● Your Personal Information.</strong>  We store your Personal Information for different time
                    periods depending on the category of Personal Information or you request us to delete
                    your data (by contacting us at privacy@dende.ai). Some information may be deleted
                    automatically based on specific schedules, such as marketing information. Other
                    information e.g. account information, may be retained for a longer period of time unless
                    you request us to delete your data (by contacting us at privacy@dende.ai). Finally, we
                    may further retain information for business practices based on our legitimate interests or
                    legal purposes, such as network improvement, fraud prevention, record-keeping, or
                    enforcing our legal rights.
                    <strong>● Your Contents.</strong>  We keep your Contents and prompts on our servers as long as they are
                    necessary to give context to the Models and to allow them to perform the Tasks. After the
                    Tasks have been completed, all the prompts (both input and output) are stored on our
                    servers and we might use them to train and/or create our artificial intelligence/products.
                    However, we shall not use your Personal Information (as defined in our Privacy Policy)
                    to generally train and/or create our separate artificial intelligence/products.
                </div>
                <div className="para">
                    We inform you that, in case of revocation of your consent and, in any case, at the expiration of
                    terms indicated above, we will erase or anonymize your Personal Information from our archives.
                    We may retain your Personal Information in connection with your privacy-related requests and
                    communications with us, if any, as necessary to comply with our legal obligations, to resolve
                    disputes, and to enforce our agreements. Even if we delete some or all of your Personal
                    Information, we may continue to retain and use anonymized data previously collected that can no
                    longer be used for personal identification.
                </div>
            </div>



            <div className="privacy_text">
                <div className="heading">10.What are your rights (data subject rights -DSR)</div>
                <div className="para">
                    <strong>We want you to be in control of your Personal Information, so we provide you with the
                        following tools:</strong><br />
                    You have the right to obtain from us the indication of:<br />
                    ● the origin of the Personal Information;<br />
                    ● the purpose and method of processing the Personal Information;<br />
                    ● the logic applied in cases where processing is carried out with the aid of electronic
                    instruments;<br />
                    ● the identification of the Data Controller, and Data Processors;<br />
                    ● the subjects or categories of subjects to whom the personal data may be communicated or
                    who can learn about them as an appointed representative in the territory of the EEA or even
                    in countries outside of the EEA where data is processed on our behalf.<br />
                    <br />
                    In particular, you have the right to obtain:<br />
                    <br />
                    ● the access, the updating, rectification or, when it is the case, the integration of your
                    Personal Information;<br />
                    ● the cancellation, transformation into an anonymous format or restriction of data
                    processing in case of a violation of the law, or when Personal Information retention is not
                    necessary in relation to the purposes for which the Personal Information was collected or
                    subsequently processed;<br />
                    ● certification/declaration that the operations referred to in letters a) and b) above have been
                    disclosed, also in reference to their content, of those to whom the data have been shared or
                    disclosed, except in case where such fulfillment proves impossible or involves the use of
                    means obviously disproportionate to the protected right;<br />
                    <br />
                    Furthermore:<br />
                    <br />
                    ● you have the right to revoke the consent without prejudice to the lawfulness of the
                    processing carried out based on the consent already given;<br />
                    ● when applicable, you can also obtain a copy of your information in a portable format, so you
                    can move it or store it wherever you want.<br />
                    ● you can change your preferences with regards to the cookies and other trackers at any time
                    by clicking on the persistent cookie icon at the bottom of the screen on our Website.
                    Finally, you have the right to object, in whole or in part to the processing of your Personal
                    Information:<br />
                    ● for legitimate reasons, even when it is related to the purpose of the collection of your
                    Personal Information;<br />
                    ● in case of sending of advertising materials or direct sales or for carrying out market research
                    or commercial communications;<br />
                    ● anytime Personal Information is processed for direct marketing purposes, including profiling,
                    to the extent that such activity is connected to direct marketing:<br />
                    ● in case of automated decision making, if such a decision is based solely on automated
                    processing and produces legal effects or significantly affects you.<br />
                    <br />
                    Because your privacy is important to us, we will ask you to verify your identity or provide
                    additional information before we let you access or update your personal information. We may
                    also reject your request to access or update your personal information for a number of reasons,
                    including, for example, if the request risks the privacy of other users or is unlawful.
                </div>
            </div>



            <div className="privacy_text">
                <div className="heading">11.How you can exercise your rights</div>
                <div className="para">
                    If you would like exercise the rights referred to in Article 15 to 22 of the GDPR, or the
                    California Consumer Privacy Act (‘CCPA’) or Virginia, Colorado, Utah, and Connecticut you
                    can send us an email to privacy@dende.ai. Our privacy team will examine your request and
                    respond to you as quickly as possible and no longer than 30 days from the moment you exercise
                    your right. Please note that we may still use any aggregated and de-identified Personal
                    Information that does not identify any individual and may also retain and use your Personal
                    Information as necessary to comply with our legal obligations, resolve disputes. We do not
                    charge a fee to process or respond to your verifiable consumer request unless it is excessive,
                    repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell
                    you why we made that decision and provide you with a cost estimate before completing your
                    request.
                </div>
                <div className="para">
                    We remind you that you have a right to lodge a complaint with a supervisory authority within
                    EEA should you feel unsatisfied with our treatment of your Personal Information.
                </div>
            </div>



            <div className="privacy_text">
                <div className="heading">12.Specific geographies rights</div>
                <div className="para">
                    <strong>Residents of California, Virginia, Colorado, Utah, and Connecticut</strong> may have statutory rights
                    under state comprehensive privacy law including the rights specified below. You can exercise
                    these rights by contacting us at privacy@dende.ai.
                </div>
                <div className="para">
                    <strong>● Right to request the categories of Personal Information collected about you.</strong> We will
                    provide, where relevant and required by law, the types of data we collect.<br />
                    <strong>● Right to request the categories of sources from which your Personal Information is
                        collected.</strong> We will provide, where relevant and required by law, the types of tools and
                    organizations we use to collect data.<br />
                    <strong>● Right to request the business or commercial purpose for collecting your Personal
                        Information.</strong> We will provide, where relevant and required by law, the purposes to
                    collect data.<br />
                    <strong>● Right to request the categories of third parties to whom Personal Information is
                        disclosed, and the categories of Personal Information disclosed.</strong> We will provide,
                    where relevant and required by law, information regarding the other organizations that
                    may receive your Personal Information.<br />
                    <strong>● Right to request the specific pieces of Personal Information collected about you.</strong> We
                    will provide, where relevant and required by law, your Personal Information.<br />
                    <strong>● Right to request that Personal Information collected about you be deleted.</strong> We may
                    keep Personal Information for legal reasons but will accommodate deletion requests
                    when required. Please be aware that erasing some Personal Information may affect your
                    ability to use our Services.<br />
                    <strong>● Request that your inaccurate Personal Information be corrected.</strong> If you believe your
                    Personal Information is not correct you may provide Personal Information to replace the
                    erroneous data.<br />
                    <strong>● Request that We do not sell or share your Personal Information.</strong> Each state may
                    interpret a “sale” of Personal Information differently, however, if you are a resident of the
                    states above or Nevada and request that your Personal Information not be sold the
                    Company will honor that request. Individuals in California may request their information
                    not be shared with any third party.<br />
                    <strong>● Right to non-discrimination.</strong> The Company will not discriminate against you (e.g.,
                    through denying goods or services or providing a different level or quality of goods o/r
                    services) for exercising any of the rights afforded to you.<br />
                    <strong>● California and Delaware “Do Not Track” disclosures.</strong> California and Delaware law
                    require the Company to indicate whether it honors your browser’s “Do Not Track”
                    settings concerning targeted advertising. The Company adheres to the standards set out in
                    this Policy and does not monitor or respond to Do Not Track browser requests.<br />

                </div>
                <div className="para">
                    Please keep in mind that in case of a vague request to exercise any of the aforementioned right
                    we may engage with you in a dialogue to ask for more details if so needed to complete your
                    request. In case this is impossible, we reserve the right to refuse granting your request.
                </div>
                <div className="para">
                    Following the provisions of the applicable law, we might also ask you to prove your identity (for
                    example, by requesting your user or some other proof of your identity) in order for you to invoke
                    the mentioned rights. This is made to ensure that no right of third parties is violated by your
                    request, and the mentioned rights are exercised by an actual Personal Information subject or an
                    authorized person.
                </div>
                <div className="para">
                    <strong>Opting Out of Promotional Emails</strong><br />
                    IF REQUIRED BY LAW, WE WILL ASK FOR YOUR CONSENT TO SEND YOU
                    PROMOTIONAL AND MARKETING EMAILS. IF YOU WISH TO OPT-OUT OF OUR
                    PROMOTIONAL AND MARKETING EMAILS, YOU CAN OPT-OUT FROM OUR
                    PROMOTIONAL AND MARKETING EMAIL LIST BY USING ANY OF THE FOLLOWING
                    METHODS:<br />
                    ● by following the opt-out links in any marketing email sent to you; or through our
                    Services settings on your mobile device; or<br />
                    ● by contacting us at any time using the contact details above
                </div>
                <div className="para">
                    <strong>Users’ Age</strong><br />
                    Our services are not intended for — and we don’t direct them to — anyone under 18. And that’s
                    why we do not knowingly collect Personal Information from anyone under 18. If you are under
                    18, do not: (i) use or provide any information to our Services or through any of its features, or
                    (ii) provide any information about yourself to us, including your name, address, telephone
                    number or email address. In the event that we learn that we have collected or received any
                    Personal Information from a user under 18 we will delete that information as quickly as possible.
                    If you believe we might have any information from or about a user under 18, please contact us.
                </div>



            </div>



            <div className="privacy_text">
                <div className="heading">13.Linked websites</div>
                <div className="para">
                    For your convenience, hyperlinks may be posted on the Services that link to other websites (the
                    “Linked Sites”). We are not responsible for, and this Policy does not apply to, the privacy
                    practices of any Linked Sites or of any companies that we do not own or control. Linked Sites
                    may collect information in addition to that which we collect through our services. We do not
                    endorse any of these Linked Sites, the services or products described or offered on such Linked
                    Sites, or any of the content contained on the Linked Sites. We encourage you to seek out and
                    read each Linked Site’s privacy notice to understand how the Personal Information about you is
                    used and protected.
                </div>

            </div>
            <div className="privacy_text">
                <div className="heading">14 Registration through "Facebook," “Google, ”and “Apple”</div>
                <div className="para">
                    If you are a Facebook member, you can register on the Site using the "Continue with Facebook"
                    option. The data that Facebook may communicate to us are the following: name, surname,
                    profile picture, e-mail address on which the user can be contacted by Facebook (with 'actual'
                    address or address on the proxymail.facebook.com domain), the address and date of birth.
                </div>
                <div className="para">
                    We will use this data to facilitate the registration procedure, by pre-filling the fields on the
                    registration form. You will then be able to access the Site or the app using the credentials you use
                    to access Facebook.
                </div>
                <div className="para">
                    Find more information at the following links: http://www.facebook.com/help/405977429438260/
                    and https://www.facebook.com/about/privacy/your-info-on-other.
                </div>
                <div className="para">
                    If you have an Apple or Google account, you can register on the Site or the app using the
                    "Continue with Apple or Google" option. The data that Apple or Google may communicate to us
                    are the following: name, surname, e-mail address associated with the Apple account and a User
                    identification token.
                </div>
                <div className="para">
                    We will use this data to facilitate the registration procedure, by pre-filling the fields on the
                    registration form. You will then be able to access our Services using the credentials you use to
                    access your Apple or Google account.
                </div>
                <div className="para">
                    For more information see the following link: https://www.apple.com/legal/privacy/en-ww/, and
                    https://policies.google.com/privacy?hl=en-US
                </div>

            </div>


            <div className="privacy_text">
                <div className="heading">15 Revisions to the Privacy Policy</div>
                <div className="para">
                    The date this Policy was last revised is indicated at the top of the page. The Company may
                    modify or update this Policy from time to time. Some changes do not require your consent: for
                    example, when we add a new purpose of processing that is compatible with the existing
                    purposes, or the new processing activity that falls under the users’ reasonable expectation.
                    However, if the changes made may pose risk to your rights and freedoms (e.g., by including a
                    new purpose of the processing that is not compatible with the existing purposes of processing, a
                    new legal basis, a new category of personal data to be collected or a new data subject, all of
                    which are not reasonably expected by the users, we will ask for your consent to those changes
                    separately from this Policy. If you did not receive a request for your consent to the changes or
                    refused to give consent, those changes will not apply to you. That fact can negatively affect some
                    of our Services provided to you in case those services inevitably include consent to the changes.
                </div>
            </div>
            <div className="privacy_text">
                <div className="heading">16 Contact us</div>
                <div className="para">
                    General contact details. If you have any questions about this Privacy Policy or the Company, please
                    contact us via email at privacy@dende.ai or our mailing address:
                </div>
                <div className="subheading">
                    Dende Srl<br/>
                    Corso Buenos Aires 23<br/>
                    20124 Milano (MI)<br/>
                    VAT: 13448310964<br/>
                </div>
                <div className="subheading">
                Controller’s Contact Information:
                </div>
                <div className="para">privacy@dende.ai</div>
            </div>

        </div>
    )
}

export default Privacy
