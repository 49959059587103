import React, { useEffect, useState } from "react";
import "./Reply.scss";
import Filter from "../../Components/Filter/Filter";
import Topbar from "../../Components/Topbar/Topbar";
import { Button, Drawer } from "antd";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  AiOutlineClose,
  AiOutlinePlus,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Tabs } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { evaluateAnswer } from "../../helpers/requests";
import { useSelector } from "react-redux";

const Reply = ({
  questionIndex,
  totalIndex,
  question,
  setPopup,
  saveDraft,
  saveAnswer,
  setQuestionIndex,
}) => {
  let delay = 1500;
  const contents = useSelector((state) => state.master.data?.contents || null);
  const [files, setFiles] = useState({});

  const [left, setLeft] = useState(false);
  const [open, setOpen] = useState(false);
  const [tipopen, settipOpen] = useState(false);
  const [isSavedDraft, setIsSavedDraft] = useState(
    question?.draft ? true : false
  );

  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(question.draft ? question.draft : "");

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleChange = (event) => {
    setIsSavedDraft(false);
    setText(event.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (text && text.length) {
      saveDraft(text);
    }
  };

  const handleSaveDraft = () => {
    setIsEditing(false);
    if (text && text.length) {
      setIsSavedDraft(true);
      saveDraft(text);
    }
  };

  const handleNext = () => {
    setQuestionIndex((questionIndex + 1) % totalIndex);
    setPopup(false);
  };

  const markComplete = () => {
    if (text && text.length) {
      saveAnswer(text);
    } else {
      toast.error("Please add some text first.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: delay / 2,
      });
    }
  };
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [rate, setRate] = useState("");

  useEffect(() => {
    let _files = {};
    if (contents && contents.length) {
      contents.forEach((content) => {
        _files[content.id] = content.language;
      });
      setFiles(_files);
    }
  }, [contents]);

  const evaluateThisAnswer = async (question) => {
    setLoading(true);
    const requestData = {
      question: question?.statement,
      answer: question.answer,
      attempt: text,
      language: files[question.content_id],
    };
    const response = await evaluateAnswer(requestData);
    setLoading(false);
    if (response.status) {
      showDrawer();
      setComment(response.data.comment);
      setRate(response.data.rate);
    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: delay / 2,
      });
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [next, setNext] = useState(0);

  const showtipDrawer = () => {
    settipOpen(true);
  };

  const ontipClose = () => {
    settipOpen(false);
  };

  const nextpara = () => {
    if (next < textpara.length - 1) {
      setNext(next + 1);
    }
  };

  const prevpara = () => {
    if (next > 0) {
      setNext(next - 1);
    }
  };

  const onChange = (key) => {
    console.log(key);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [textpara, setTextpara] = useState([]);

  const tabitem = [
    {
      key: "1",
      label: `Reply`,
      children: (
        <div className="main_reply_tab">
          <div className="para">
            <textarea
              value={text}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus
            />

            {/* <p onClick={handleFocus}>
              {text && text.length > 0
                ? text
                : "Click here to type your answer..."}
            </p> */}
          </div>
          <div className="action">
            {question.draft && question.draft.length
              ? "draft saved in library"
              : ""}
          </div>
          <div className="btn_rply">
            <button
              className={
                text.length === 0 || loading ? "button disabled" : "button"
              }
              onClick={() => evaluateThisAnswer(question)}
              disabled={text.length === 0 || loading}
            >
              {loading ? "Valuating..." : "Valuate answer"}
            </button>
            <div className="text" onClick={() => markComplete()}>
              Mark as complete
            </div>

            <p style={{ fontSize: "15px", marginTop: "20px" }}>
              Happy about your answer?
            </p>

            <div className="save_it">
              <button
                className={isSavedDraft ? "buttonSaved" : "buttonNotSaved"}
                onClick={() => handleSaveDraft(question)}
              >
                <BsCheckCircle style={{ marginRight: "20px" }} />{" "}
                {isSavedDraft ? "Saved" : "Save it"}
              </button>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: `Tips`,
      children: (
        <Slider {...settings}>
          {textpara.map((e) => {
            return (
              <>
                <div className="para">{e.para}</div>
              </>
            );
          })}
        </Slider>
      ),
    },
  ];

  useEffect(() => {
    if (question && question.tips && question.tips.length) {
      if (typeof question.tips === "string") {
        question.tips = JSON.parse(question.tips);
      }
      let tips = question.tips.map((tip) => {
        return {
          para: tip,
        };
      });
      setTextpara(tips);
    }
  }, [question]);

  return (
    <>
      <ToastContainer />
      <Topbar
        question={question}
        setPopup={(e) => setPopup(e)}
        markComplete={() => markComplete()}
      />
      <div className="main_reply">
        <div
          style={{ marginTop: "120px" }}
          className={left ? "reply_for_padding " : "reply_content"}
        >
          <div className="title">
            Reply
            <div
              className="tip"
              style={{ top: "200px" }}
              onClick={showtipDrawer}
            >
              Tips
            </div>
          </div>
          <div className="para">
            <textarea
              value={text}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus
            />
            <div className="action">
              {question.draft && question.draft.length
                ? "draft saved in library"
                : ""}
            </div>
            {/* <p
              style={{ width: "500px", minHeight: "200px" }}
              onClick={handleFocus}
              dangerouslySetInnerHTML={{
                __html:
                  text && text.length > 0
                    ? text
                    : "Click here to type your answer...",
              }}
            ></p> */}
            <div className="btn_rply">
              <button
                className={
                  text.length === 0 || loading ? "button disabled" : "button"
                }
                onClick={() => evaluateThisAnswer(question)}
                disabled={text.length === 0 || loading}
              >
                {loading ? "Valuating..." : "Valuate answer"}
              </button>
              <div className="text" onClick={() => markComplete()}>
                Mark as complete
              </div>

              <p style={{ fontSize: "15px", marginTop: "20px" }}>
                Happy about your answer?
              </p>

              <div className="save_it">
                <button
                  className={isSavedDraft ? "buttonSaved" : "buttonNotSaved"}
                  onClick={() => handleSaveDraft(question)}
                >
                  <BsCheckCircle style={{ marginRight: "20px" }} />{" "}
                  {isSavedDraft ? "Saved" : "Save it"}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              onClick={handleNext}
              style={{
                position: "absolute",
                right: "2%",
                top: "50%",
                fontSize: "14px",
                transform: "translateY(-50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "white",
                  borderRadius: "50%",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "32px",
                  height: "32px",
                  margin: "10px",
                }}
              >
                <AiOutlineArrowRight />
              </div>
              <p>Next</p>
            </div>
          </div>
        </div>
        <div className="swipe_tips">
          <Tabs defaultActiveKey="1" items={tabitem} onChange={onChange} />
        </div>
      </div>
      <Drawer placement="right" open={tipopen}>
        <div className="tips_content">
          <div className="cross">
            <AiOutlineClose
              onClick={ontipClose}
              style={{ color: "#4D4D4D", cursor: "pointer" }}
            />
          </div>
          <div className="para_main">
            <div className="para">
              {textpara.length > 0 && textpara[next].para}
            </div>
          </div>
          <div className="next_btn">
            <GrFormPrevious onClick={prevpara} className="icon" />
            {next + 1}/{textpara.length}
            <GrFormNext className="icon" onClick={nextpara} />
          </div>
        </div>
      </Drawer>
      <Drawer placement="bottom" open={open}>
        <div className="valuate_content">
          {rate && comment && (
            <>
              <AiOutlineClose className="icon" onClick={onClose} />
              <div className="subhead">Beta</div>
              <div className="title">It's {rate.toLowerCase()}!</div>
              <div className="para">{comment}</div>
              <div className="subhead_sec">
                Please accept some inaccuracies. Dende is improving day by day.
              </div>
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default Reply;
