import { useRef, useEffect, useState } from "react";
import style from "./ResetPassword.module.scss"
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { resetPassword } from "../../helpers/requests";


export default function ResetPassword(){
    const password_1 = useRef(null);
    const password_2 = useRef(null);
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search);
    const [user_id, set_user_id] = useState(null);
    const [reset_token, set_reset_token] = useState(null);

    useEffect(()=>{
        if(queryParameters.get("data1") !== null && queryParameters.get("data2") !== null){
            set_user_id(queryParameters.get("data1"));
            set_reset_token(queryParameters.get("data2"));
        }
    }, []);

    async function handleResetPassword(){
        if(password_1.current.value === password_2.current.value){
            if(user_id !== null && reset_token !== null){
                let request_data = {
                    "user_id": user_id,
                    "token": reset_token,
                    "password": password_1.current.value
                }
                let api_response = await resetPassword(request_data);
                if(api_response.message.toLowerCase() === "your account password has been updated successfully"){
                    toast.success("your account password has been updated successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: true,
                    });
                    setTimeout(()=>{
                        navigate("/login");
                    }, 2000);
                } else {
                    toast.error(api_response.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: true,
                    });
                }
            } else {
                toast.error("token missing", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: true,
                });
            }
        } else {
            toast.error("password mismatch", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: true,
            });
        }
    }

    return(
        <>
            <section className={style["container"]}>
                <div className={style["content"]}>
                    <h2>Reset Password</h2>
                    <div className={`${style["password-1"]} ${style["password-box"]}`}>
                        <p>Password</p>
                        <input ref={password_1} type="text" name="" id="" />
                    </div>
                    <div className={`${style["password-2"]} ${style["password-box"]}`}>
                        <p>Confirm Password</p>
                        <input ref={password_2} type="text" name="" id="" />
                    </div>
                    <button onClick={()=>{handleResetPassword()}}>Reset password</button>
                    <div style={{marginTop: "25px"}} className={`${style["link"]} ${style["login"]}`}>
                        Remember your password <span><Link to="/login">Login</Link></span>
                    </div>
                    <div className={`${style["link"]} ${style["signup"]}`}>
                        Create a new account  <span><Link to="/signup">Signup</Link></span>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    )
}