import { useNavigate } from "react-router";
import { useEffect, useRef, useState, useContext } from "react";
import style from "./FlashcardNew.module.scss";
import arrowIcon from "../../assets/arrow-with-circle.png"
import settingsIcon from "../../assets/setting-line-circle.png"
import smallCloseIcon from "../../assets/small-close.png"
import { 
    getUserAuthToken,
    getApiUrl,
    listAllUnskippedResources,
    getFlashcardAPI,
    ExamSpaceUploadResource,
    GetUserInfoAPI,
    flashcardAnalyticsAPI
} from "../../helpers/requests";
import { ToastContainer, toast } from "react-toastify";
import uploadIcon from "../../assets/upload-white-icon.png"
import loadingsmall from "../../assets/loading-svg-orange.svg"
// import LoadingGif from "../../assets/loading-svg.svg"
import LoadingGif from "../../assets/loading-svg-orange.svg"
import { CurrentUserInfo } from "../../Components/ContextStore/ContextStore";
import FileUploadComponent from '../../Components/FileUploadComponent/FileUploadComponent';


export default function FlashcardNew(){
    const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
    const global_default_examspace_id = useRef("");

    const [answer_visible, set_answer_visible] = useState(false);
    const [show_filter_popup, set_show_filter_popup] = useState(false);
    const [resource_data, set_resource_data] = useState([]);
    const [selected_resource, set_selected_resource] = useState({});
    const [question_index_position, set_question_index_position] = useState(0);
    const [current_quiz_content, set_current_quiz_content] = useState([]);
    const slider_reference = useRef(null);    
    let navigate = useNavigate();
    var token = getUserAuthToken();
    var apiUrl = getApiUrl();
    const [data_available, set_data_available] = useState(true);
    const [resource_available, set_resource_available] = useState(true);
    const [question_available, set_question_available] = useState(true);
    const pollIntervalId = useRef(null);
    // new
    // const [flashcardsArray, setFlashcardsArray] = useState([]);
    const [showQuestionLoading, setShowQuestionLoading] = useState(false);
    const flashcardsArray = useRef(null);
    const fileUploadRef = useRef(null);

    useEffect(()=>{
        setShowQuestionLoading(true)
        proxyCallGetDefaultExamspaceIdUtility();
        return ()=>{
            clearInterval(pollIntervalId.current);
        }
    }, []);

    async function proxyCallGetDefaultExamspaceIdUtility(){
        let examspace_id = await getDefaultExamspaceIdUtility();
        checkEmptyState();
    }

    function pollData(){
        let timeDuration  = 0;
        pollIntervalId.current = setInterval(async ()=>{
            timeDuration += 20;
            if(timeDuration >= 600){
                clearInterval(pollIntervalId.current);
            }

            let flashcard_response = await getFlashcardAPI(selected_resource["resource_id"]);
            if(flashcard_response && flashcard_response.data["question_array"].length > 0){
                set_question_index_position(0);
                set_question_available(true);
                set_data_available(true);

                clearInterval(pollIntervalId.current);

                flashcardsArray.current = flashcard_response.data.question_array;
                set_current_quiz_content([flashcardsArray.current[0]]);
                setShowQuestionLoading(false);
            } else {
                set_question_available(false);
                set_data_available(false);
            }

        }, 20000);
    }

    async function checkEmptyState(){
        let resource_list_response = await listAllUnskippedResources(global_default_examspace_id.current);
        if(resource_list_response.data.length > 0){
            set_resource_available(true);
            set_resource_data(resource_list_response.data);
            set_selected_resource(resource_list_response.data[0]);

            let flashcard_response = await getFlashcardAPI(resource_list_response.data[0]["resource_id"]);
            if(flashcard_response.data["question_array"].length > 0){
                set_question_index_position(0);
                set_question_available(true);
                set_data_available(true);

                clearInterval(pollIntervalId.current);

                flashcardsArray.current = flashcard_response.data.question_array;
                set_current_quiz_content([flashcardsArray.current[0]]);
                setShowQuestionLoading(false);

            } else {
                set_question_available(false);
                set_data_available(false);
                pollData();
            }
        } else {
            setShowQuestionLoading(false);
            set_resource_available(false);
            set_data_available(false);
        }
    }

    function handleUploadSuccess(response){
        checkEmptyState();
    }
    
    function triggerFileUpload() {
        fileUploadRef.current.triggerFileInput();
    }


    // async function getResourceList(){
    //     let resource_list_response = await listAllUnskippedResources(global_default_examspace_id.current);
    //     if(resource_list_response.data.length > 0){
    //         set_resource_data(resource_list_response.data);
    //         set_selected_resource(resource_list_response.data[0]);
    //         getFlashcardList(resource_list_response.data[0]);
    //     }
    // }


    function toggleAnswer(target){
        if(!target.className.includes("slider-arrow-click")){
            set_answer_visible(!answer_visible);
            if(answer_visible === false){
                send_flashcard_analytics(current_quiz_content[0], "flashcard_flipped");
            }
        }
    }
    function toggleFilterPopup(target){
        if(target.className.includes("filter-backdrop")){
            set_show_filter_popup(false);
        }
    }

    function handleFileBtnSelected(target, resource_json){
        // set_selected_resource(resource_json);
        let btn_list = document.getElementsByClassName(style["filter-files-btn"]);
        Array.from(btn_list).forEach((element) => {
            element.style.outline = "none";
        });
        target.style.outline = "1px solid #000";
        getFlashcardList(resource_json);
        set_show_filter_popup(false);
        // set_question_index_position(0);
        // getSingleQuestion(resource_json);
        
    }
    function nextQuestion(question_id){
        switchQuestion(question_id, "next")
        set_answer_visible(false);
        // if((question_index_position) <= (current_quiz_content[0]["question_count"] - 1)){
        //     // getSingleQuestion();
        // }
    }
    function previousQuestion(question_id){
        switchQuestion(question_id, "previous")
        set_answer_visible(false);

        // if(question_index_position === 0){
        //     set_question_index_position(current_quiz_content[0]["question_count"] - 1)
        // }
        // set_question_index_position(question_index_position - 1);
        // getSingleQuestion();
        // set_answer_visible(false);
    }



    async function getFlashcardList(request_data){
        setShowQuestionLoading(true);
        let apiResponse = await getFlashcardAPI(request_data.resource_id);
        if(apiResponse.status){
            // setFlashcardsArray(apiResponse.data.question_array);
            flashcardsArray.current = apiResponse.data.question_array;
            set_current_quiz_content([flashcardsArray.current[0]]);
            setShowQuestionLoading(false);
        } else {
            toast.error(apiResponse.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            setShowQuestionLoading(false);
        }
    }

    function switchQuestion(question_id, action){
        let current_index = flashcardsArray.current.findIndex(obj => obj.question_id === question_id);
        let arrayTotalIndex = flashcardsArray.current.length - 1;
        if(action === "next"){
            let nextItemIndex = current_index + 1;
            if(nextItemIndex > arrayTotalIndex){
                nextItemIndex = 0
                set_current_quiz_content([flashcardsArray.current[nextItemIndex]]);
                send_flashcard_analytics(flashcardsArray.current[nextItemIndex], "flashcard_seen");
            } else {
                set_current_quiz_content([flashcardsArray.current[nextItemIndex]]);
                send_flashcard_analytics(flashcardsArray.current[nextItemIndex], "flashcard_seen");
            }
        }
        else if(action === "previous"){
            let previousItemIndex = current_index - 1;
            if(previousItemIndex < 0){
                previousItemIndex = arrayTotalIndex;
                set_current_quiz_content([flashcardsArray.current[previousItemIndex]]);
                send_flashcard_analytics(flashcardsArray.current[previousItemIndex], "flashcard_seen");
            } else {
                set_current_quiz_content([flashcardsArray.current[previousItemIndex]]);
                send_flashcard_analytics(flashcardsArray.current[previousItemIndex], "flashcard_seen");
            }
        }
    }

    function send_flashcard_analytics(question_data, event_type){
        let request_json = {
            "event_type": event_type,
            "question_id": question_data["question_id"],
            "resource_id": question_data["resource_id"],
            "passage_id": question_data["passage_id"],
            "resource_uri": question_data["resource_uri"],
            "style": question_data["style"],
            "language": question_data["language"],
        }
        flashcardAnalyticsAPI(request_json)
    }

    async function getDefaultExamspaceIdUtility(){
    return new Promise( async(resolve, reject) => {
        let default_examspace_id = "";
        if( Object.keys(sharedUserInfo).length > 0){
            default_examspace_id = sharedUserInfo.default_examspace_id;
            global_default_examspace_id.current = sharedUserInfo.default_examspace_id;
            resolve(default_examspace_id);
        } 
        else {
        let getResponse = await GetUserInfoAPI()
        if (getResponse.status) {
            if(getResponse.data.length > 0){
                if(Object.keys(getResponse.data[0]).length > 0){
                    setSharedUserInfo(getResponse.data[0]);
                    default_examspace_id = getResponse.data[0]["default_examspace_id"];
                    global_default_examspace_id.current = getResponse.data[0]["default_examspace_id"];
                    resolve(default_examspace_id);
                }
            }
        }
        }
        
    });
    }  
        

    return(
        <> 
        
        {/* <Sidebar/> */}
            <section className="quiz-empty-state-container"
                style={data_available === false ? {display: "flex"} : {display: "none"}}>
                <div className="quiz-empty-state-content">
                    {/* upload file UI */}
                    <div className="quiz-empty-state-upload-file" 
                    style={resource_available === false ? {display: "block"} : {display: "none"}}> 
                    <p>Upload at least 1 document to see some questions here</p>
                    <FileUploadComponent
                        ref={fileUploadRef}
                        onUploadSuccess={handleUploadSuccess}
                        examspaceId={global_default_examspace_id.current}
                    />
                    <button onClick={triggerFileUpload}>
                        <img src={uploadIcon} alt="upload icon"/>
                        Upload a file
                    </button>
                    </div>
                    {/* Generating questions UI */}
                    <div className="quiz-empty-state-generate"
                        style={question_available === false ? {display: "block"} : {display: "none"}}>
                        <img src={loadingsmall} alt="loading" />
                        <p>We are generating questions from your documents. Please hold on a few seconds</p>
                    </div>
                </div>
            </section>
            <main className={style["container"]}
                style={data_available === true ? {display: "flex"} : {display: "none"}}>
                    {
                        current_quiz_content.length
                        ?
                            <>
                                <div className={style["content"]}>
                                    <div className={`${style["slider-container"]} animate__animated animate__slideInLeft`}>
                                        <div id="flashcard_show_answer" ref={slider_reference} className={style["slider"]} onClick={(e)=>{toggleAnswer(e.target)}}>
                                            <h2 className={style["question"]} 
                                                style={answer_visible === false ? {display: "block"} : {display: "none"}}>
                                                {
                                                    current_quiz_content.length
                                                    ? 
                                                        <>
                                                        {current_quiz_content[0]["question_content"]["question"]}
                                                        </>
                                                    :
                                                        <>
                                                        </>
                                                }
                                            </h2>
                                            <p className={style["answer"]}
                                                style={answer_visible === true ? {display: "block"} : {display: "none"}}>
                                                {
                                                    current_quiz_content.length
                                                    ?
                                                        current_quiz_content[0]["question_content"]["options"].map((item)=>{
                                                            if(item["is_correct"] === true){
                                                                return(
                                                                    <>
                                                                    {item["text"]}
                                                                    </>
                                                                )
                                                            }
                                                        })
                                                    : 
                                                        null
                                                }
                                            </p>
                                            <aside id="flashcard_previous_question" className={`${style["slider-arrow-click"]} ${style["slider-arrow"]} ${style["slider-arrow-left"]}`}
                                                onClick={()=>{previousQuestion(current_quiz_content[0]["question_id"])}} >
                                                <img className={style["slider-arrow-click"]} src={arrowIcon} alt="arrow left" />
                                            </aside>
                                            <aside id="flashcard_next_question" className={`${style["slider-arrow-click"]} ${style["slider-arrow"]} ${style["slider-arrow-right"]}`}
                                                onClick={()=>{nextQuestion(current_quiz_content[0]["question_id"])}}>
                                                <img className={style["slider-arrow-click"]} src={arrowIcon} alt="arrow right" />
                                            </aside>
                                        </div>
                                    </div>
                                </div>
                            </>
                        :
                            <></>
                    }
                <aside id="flashcard_filter" className={style["filter-icon"]} onClick={()=>{set_show_filter_popup(true)}}>
                    <img src={settingsIcon} alt="settings icon" />
                </aside>
                <section className={style["filter-backdrop"]}
                    style={show_filter_popup === true ? {display: "block"} : {display : "none"}}
                    onClick={(e)=>{toggleFilterPopup(e.target)}}>
                    <div className={style["filter-content"]}>
                        <img className={style["filter-close-icon"]} src={smallCloseIcon} alt="close icon" 
                            onClick={()=>{set_show_filter_popup(false)}}/>
                            <h3 className={style["filter-title"]}>
                                Quiz Filter
                            </h3>
                            <h6 className={style["filter-btn-title"]} style={{marginTop: "30px"}}>
                                Files
                            </h6>
                            <div style={{marginTop: "15px"}} 
                                className={`${style["filter-btn-group"]} ${style["filter-files-btn-group"]}`}>
                                {resource_data.length ?
                                    resource_data.map((item, index)=>{
                                        return(
                                            <>
                                                <button className={style["filter-files-btn"]}
                                                    style={selected_resource["resource_id"] === item["resource_id"] ? {outline: "1px solid #000"} : {outline: "none"}}
                                                    onClick={(e)=>{handleFileBtnSelected(e.target, item)}}>
                                                    {item["resource_title"].length > 30 ? item["resource_title"].substring(0, 30)+"..." : item["resource_title"] }
                                                </button>
                                            </>
                                        )
                                    })
                                    : null
                                }
                            </div>
                    </div>
                </section>
            </main>
            <section 
                className={style["resource-loading-container"]}
                style={showQuestionLoading ? {display: "flex"} : {display: "none"}}
            >
                <div className={style["resource-loading-content"]}>
                    <img src={LoadingGif} alt="gif" />
                    <h2>Loading flashcard question...please wait few seconds..</h2>
                </div>
            </section>
            <ToastContainer />
        </>
    )
}