import React, { useEffect, useState } from 'react'
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Space, Table, Modal, Button } from 'antd';
import { Link } from 'react-router-dom';
import "./Users.scss"
import { useDispatch, useSelector } from 'react-redux';
import { deleteAccount, getAllUsers } from '../../helpers/requests';


const Users = () => {
    const dispatch = useDispatch();
    const users = useSelector(state => state.users);
    const [modal, contextHolder] = Modal.useModal();
    const [data, setData] = useState([])
    const handleOk = (id) => {
        
        deleteAccount({dispatch, id: id});
    }
    const handleCancel = () => {

    }
    const confirm = (id) => {
        modal.confirm({
            title: "Confirm",
            icon: <ExclamationCircleOutlined />,
            content: "You cannot undo this action. Are you sure you want to delete this record.",
            okText: "Yes",
            cancelText: "Cancel",
            centered: "true",
            onOk: () => handleOk(id),
            onCancel: handleCancel
        });
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',

        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => <Link style={{ textDecoration: 'none' }} to={'/user/' + record.id}>{record.name}</Link>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
           
        },
        {
            title: 'Birthday',
            dataIndex: 'birthday',
            key: 'birthday',
            render: (_, record) => (new Date(record.birthday).toLocaleString().split(",")[0]),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Link style={{ textDecoration: 'none' }} to={'/user/' + record.id}>
                        <Button>
                            View
                        </Button>
                    </Link>
                    {   record.role === "user" &&
                        <>
                            <Link style={{ textDecoration: 'none' }} to={'/user-activity/' + record.id}>
                                <Button>
                                    Activities
                                </Button>
                            </Link>
                            <Button onClick={()=>confirm(record.id)}>Delete</Button>
                        </>
                    }
                </Space>
            ),
           
        },

    ];

    useEffect(()=>{
        const raw = users.map(user => {
            return{
                id: user.id,
                key: user.id,
                name: user.name+' '+user.surname,
                email: user.email,
                role: user.role,
                birthday: user.birthday
            }
        });
        setData(raw);
    }, [users]);

    useEffect(()=>{
        getAllUsers({dispatch});
    }, [])

    return (
        <div className='main_users'>
            {contextHolder}
            <h1 className="title">All Users</h1>
            <div className="user_content">
                <Table bordered columns={columns} dataSource={data} />
            </div>
        </div>
    )
}

export default Users
