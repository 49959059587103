import { actionTypes } from "../constants/action-types";


const initialState = {
  user: 0,
  super_admin: 0,
  admin: 0,
  user_with_document: 0,
  user_with_document_percentage: 0,
  user_with_answers: 0,
  user_with_answers_percentage: 0,
  user_with_three_answers: 0,
  user_with_three_answers_percentage: 0,
  user_with_five_answers: 0,
  user_with_five_answers_percentage: 0,
  avg_answers_per_user: 0,
  three_sessions: 0,
  three_sessions_percentage: 0,
  three_days: 0,
  three_days_percentage: 0,
  two_minutes: 0,
  two_minutes_percentage: 0,
  avg_two_minutes: 0,
  avg_session_length: 0,
}

export const statsReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case actionTypes.SET_STATS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
