import React, { useState, useEffect, useRef } from "react";

import { Drawer, ButtonToolbar, Button, Placeholder } from "rsuite";
import { BiLink } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { RxMixerHorizontal } from "react-icons/rx";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import "./QuestionFilter.scss";
import { useSelector } from "react-redux";
import { FiHelpCircle } from "react-icons/fi";

const QuestionFilter = ({ questions, index, setQuestionIndex }) => {
  const contents = useSelector((state) => state.master.contents);
  const [open, setOpen] = useState(false);
  const [next, setNext] = useState(0);
  const location = useLocation();
  const filterRef = useRef(null);

  const library = location.pathname == "/library";
  const chapters = location.pathname == "/chapters";
  const quiz = location.pathname == "/mcq";

  const [selectedTypes, setSelectedTypes] = useState(["MCQ", "open"]);
  const [selectedFiles, setSelectedFiles] = useState(
    contents.file.data.map((file) => file.id)
  );

  const openFilter = (event) => {
    setOpen(true);
  };

  const closeFilter = () => {
    setOpen(false);
  };

  const toggleTypes = (type) => {
    const typeIndex = selectedTypes.indexOf(type);
    let types = selectedTypes.slice();
    if (typeIndex > -1) {
      types.splice(typeIndex, 1);
      setSelectedTypes(types);
    } else {
      types.push(type);
      setSelectedTypes(types);
    }
  };

  const toggleFiles = (id) => {
    const typeIndex = selectedFiles.indexOf(id);
    let ids = selectedFiles.slice();
    if (typeIndex > -1) {
      ids.splice(typeIndex, 1);
      setSelectedFiles(ids);
    } else {
      ids.push(id);
      setSelectedFiles(ids);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const targetElement = event.target;
      const filterElement = filterRef.current;
      // Check if the click is outside the filter content or on the close button
      if (
        filterElement &&
        !filterElement.contains(targetElement) &&
        !targetElement.classList.contains("close-btn")
      ) {
        closeFilter();
      } else if (targetElement.classList.contains("close-btn")) {
        closeFilter();
      }
    };

    if (open) {
      setTimeout(() => {
        document.addEventListener("click", handleOutsideClick);
      }, 0);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [open]);

  return (
    <div className="help_btn" onClick={openFilter}>
      <div className="main_filter">
        <ButtonToolbar>
          <div>
            {" "}
            <FiHelpCircle />{" "}
          </div>
        </ButtonToolbar>

        <Drawer open={open}>
          <Drawer.Body
            style={{
              borderBottomLeftRadius: "20px",
              borderTopLeftRadius: "20px",
              height: "100%",
            }}
            ref={filterRef}
          >
            <div className="filter_result">
              <h3 className="title">
                Questions
                <AiOutlineClose
                  onClick={() => closeFilter()}
                  style={{
                    color: "#4D4D4D",
                    cursor: "pointer",
                    padding: "3px",
                  }}
                  className="close-btn"
                />
              </h3>

              <div className="top_sec">
                {questions.map((question, ind) => {
                  if (ind == index) {
                    return (
                      <div
                        key={ind}
                        className="sub_heading"
                        style={{ backgroundColor: "#F0F6F5", color: "#201E1F" }}
                      >
                        {ind + 1} {question.statement}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={ind}
                        onClick={() => setQuestionIndex(ind)}
                        className="sub_heading"
                      >
                        {ind + 1} {question.statement}
                      </div>
                    );
                  }
                })}
              </div>

              {/* <div className="come">
                    <div className="icon">
                        <AiOutlinePlus />
                    </div>
                    come on, more question!
                </div> */}
            </div>
          </Drawer.Body>
        </Drawer>
      </div>
    </div>
  );
};

export default QuestionFilter;
