import React from 'react'
import { AiOutlineRight } from 'react-icons/ai';
import { FiHelpCircle } from 'react-icons/fi'
import Filter from '../../Components/Filter/Filter'
import { Link } from 'react-router-dom';
import './Question.scss'
const Question = () => {
    return (
        <div className='main_qeust'>
            <Filter />
            <div className="help_btn">
                <FiHelpCircle />
            </div>
            <div className="question_content">
                <div className="card">
                    <h2 className="title">
                        What is the concept of an inclusive economy, and why is it gaining attention?
                    </h2>
                </div>
                <Link style={{textDecoration:'none'}} to='/reply'>
                <button className='button' style={{ width: '150px' }}>Reply</button>
                </Link>
                <span className='may'>not now, move on <AiOutlineRight /> </span>
            </div>
        </div>
    )
}
export default Question
