import React,{Fragment, useEffect, useState} from 'react'
import { BsSearch } from 'react-icons/bs'
import Filter from '../../Components/Filter/Filter'
import { AiOutlineRight } from 'react-icons/ai';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
// import './Library.scss'
import { useSelector } from 'react-redux';
import style from "./Library.module.scss";


const Chapter = () => {
    const data = useSelector(state => state.master.chapters);
    const [chapters, setChapters] = useState([])
    const [searchString, setSearchString]= useState("")



    // const chapters = [
    //     {
    //         status1: 'Economy doc 1',
    //         status2: '32 questions',
    //         question: 'Inclusive economy',
    //         action1: 'Take the quiz',
    //         actionLink1:'/mcq',
    //         action2: 'See questions',
    //         actionLink2:'/question',
    //     },
    //     // {
    //     //     status1: 'Medical paper 2',
    //     //     status2: '18 questions',
    //     //     question: 'Blood stream',
    //     //     action1: 'Take the quiz',
    //     //     actionLink1:'/mcq',
    //     //     action2: 'See questions',
    //     //     actionLink2:'/question',
    //     // },
    //     // {
    //     //     status1: 'Note in class 3',
    //     //     status2: '25 questions',
    //     //     question: 'Capitalism vs Socialism opinions ',
    //     //     action1: 'Take the quiz',
    //     //     actionLink1:'/mcq',
    //     //     action2: 'See questions',
    //     //     actionLink2:'/question',
    //     // },

    // ]

    useEffect(()=>{
        let filteredChapters = [...data];
        if(searchString && searchString.length > 0){
            filteredChapters = filteredChapters.filter((chapter) => chapter.title.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
        }
        setChapters(filteredChapters);
    }, [searchString])
    
    useEffect(()=>{
        if(data && data.length > 0){
            setChapters(data);
        }
    },[data])
   
    return (
        <div className='main_library'>
            <div className="library_content">

                <div className="librar_topsec">
                    <div className="left_sec">
                        <div className="title">Chapters
                           
                        </div>
                    </div>
                    <div className="right_sec">
                       
                        <div className="search">
                            <div className="icon">
                                <BsSearch />
                            </div>
                            <input  onChange={(e)=>setSearchString(e.target.value)} type='text' placeholder='Search for keywords' />
                        </div>
                        <div>
                            {/* <Filter /> */}
                        </div>
                    </div>

                </div>

                <div className="bottom_sec">
                    <div className="main_question">
                        {chapters.filter(chapter => chapter.questionsCount > 0).map((chapter) => {
                            return (
                                <Fragment key={"chapter_"+chapter.id}>
                                    <div className="question">

                                        <div className="question_head">
                                            <div className="status">
                                                <span>{chapter.content}</span> | <span>{chapter.questionsCount} questions</span>
                                            </div>
                                            <div className="title">{chapter.title}</div>
                                        </div>
                                        <div className="action_main">
                                            <Link style={{textDecoration:'none'}} to={'/chapter/'+chapter.id}>
                                                <div className="action">
                                                    Take the quiz <AiOutlineRight />
                                                </div>
                                            </Link>
                                            <Link style={{textDecoration:'none'}} to={'/chapter/'+chapter.id+'/questions'}>
                                                <div className="action">
                                                    See questions <AiOutlineRight />
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="action_main_2">
                                            <Link style={{textDecoration:'none'}} to={'/chapter/'+chapter.id}>
                                                <div className="action">
                                                    Take the quiz <AiOutlineRight />
                                                </div>
                                            </Link>
                                            <Link style={{textDecoration:'none'}} to={'/chapter/'+chapter.id+'/questions'}>
                                                <div className="action">
                                                    See questions <AiOutlineRight />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        })}



                    </div>
                </div>

            </div>
        </div>
    )
}

export default Chapter
