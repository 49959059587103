import "./LoaderSpinner.scss";
import LoadingGif from "../../assets/loading-svg.svg"
import { useNavigate } from "react-router";
import { useRef, useState, useEffect } from "react";

export default function LoaderSpinner(props){
    const [loader_values, set_loader_values] = useState(false);

    useEffect(()=>{
        set_loader_values(props.data);
    }, [props.data]);



    return(
        <>
            <section style={loader_values ?  {"display":"block"} : {"display":"none"}}
                className="loading-gif-container">
                <div className="loading-gif-content">
                    <img src={LoadingGif} alt="loading gif" />
                </div>
            </section>
        </>
    )
}