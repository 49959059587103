import React, { Fragment, useEffect, useState } from 'react'
import { BsArrowDownLeft, BsArrowLeft, BsSearch } from 'react-icons/bs'
import Filter from '../../Components/Filter/Filter';
import { AiOutlineRight } from 'react-icons/ai';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import './ChapterQuiz.scss';
import { useSelector } from 'react-redux';



const ChapterQuestions = () => {
const { id } = useParams();
const data = useSelector((state) => state.master.chapters);
const [chapter, setChapter] = useState(null);
const [questions, setQuestions] = useState([]);
const [searchString, setSearchString] = useState("");

    useEffect(() => {
        if (data && data.length > 0) {
            let chapterData = data.slice().filter(chapter => chapter.id == id);
            if (chapterData.length > 0) {
                setChapter(chapterData[0]);
                let _questions = chapterData[0].questions;
                _questions = _questions.filter(question => question.type != "flashcard");
                if (searchString && searchString.length > 0) {
                    _questions = _questions.filter(question => question.statement.indexOf(searchString) > -1);
                }
                _questions = _questions.sort((q1, q2) => q1.type.length - q2.type.length)
                setQuestions(_questions);
            }
        }
    }, [data, searchString])

    return (
        <div className='main_library'>
            <div className="library_content">

                <div className="librar_topsec">
                    <div className="left_sec">
                        <div className="title"><Link to={"/chapters"} style={{marginTop: "7px", color: "#201E1F"}}><BsArrowLeft/></Link> {chapter ? "Chapter: " + chapter.title : "Chapter"}</div>
                    </div>
                    <div className="right_sec">

                        <div className="search">
                            <div className="icon">
                                <BsSearch />
                            </div>
                            <input onChange={(e) => setSearchString(e.target.value)} type='text' placeholder='Search for keywords' />
                        </div>
                        <div></div>
                    </div>

                </div>

                <div className="bottom_sec">
                    <div className="main_question">
                        {questions.map((question) => {
                            return (
                                <Fragment key={"question_" + chapter.id}>
                                    <div className="question">
                                        <div className="question_head">
                                            <div className="status">
                                                <span>{question.type.charAt(0).toUpperCase() + question.type.slice(1)}</span>
                                            </div>
                                            <div className="main_statement">
                                                <div className="title">{question.statement}</div>
                                            
                                                <div className="reply">
                                                    {/* <Link style={{textDecoration:'none'}} to={'/mcq/'+question.id}>Reply</Link> */}
                                                    <Link style={{textDecoration:'none', color: "#201E1F"}} to={'/mcq/'+question.id}>
                                                        <div className="action">
                                                            Reply <AiOutlineRight />
                                                        </div>
                                                    </Link>
                                                </div>
                                        
                                            </div>
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        })}
                    </div>
                </div>
      </div>
    </div>
  );
};

export default ChapterQuestions;
