import "./PaymentPopup.scss";
import closeIcon from "../../assets/close.svg";
import { useEffect, useState } from "react";
import { getUserAuthToken, getApiUrl } from "../../helpers/requests";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function PaymentPopup({ props, nextQuiz }) {
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();
  const navigate = useNavigate();
  const [popup_values, set_popup_values] = useState({});
  const [checkbox_values, set_checkbox_values] = useState([]);
  const [checkbox_selected, set_checkbox_selected] = useState(false);

  useEffect(() => {
    set_popup_values(props);
  }, [props]);

  return (
    <>
      <main
        className="payment-popup-container"
        style={popup_values.open ? { display: "block" } : { display: "none" }}
        // style={{"display": "block"}}
      >
        <div className="payment-popup-backdrop">
          <div className="payment-popup-content">
            <img
              onClick={() => {
                set_popup_values({ open: false, type: "", question_id: "" });
                navigate(`/home`);window.location.reload();
              }}
              className="payment-popup-close-icon"
              src={closeIcon}
              alt="close icon"
            />
            <h3>💪</h3>
            <h4>Well done, you are now in push mode!</h4>

            <div className="payment-popup-btn-container">
              <button
                className={"payment-popup-btn-active"}
                onClick={() => {
                  set_popup_values({ open: false, type: "", question_id: "" });
                  navigate(`/home`);window.location.reload();
                }}
                // onClick={()=>{sendFeedback()}}
              >
                Ace your next exam
              </button>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </>
  );
}
