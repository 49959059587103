import "./LoaderInitial.scss";
import LoadingGif from "../../assets/loading-svg-gif.svg";
import { useNavigate } from "react-router";
import { useRef, useState, useEffect } from "react";
import dendeNewLogo from "../../assets/dende-new-logo.png";
import smallSVG from "../../assets/loading-svg-orange.svg";

const LoadingContent = [
  {
    text: "We are extracting the most important concepts from your document",
    sec: 14,
  },
  {
    text: "We are extracting the most important concepts from your document </br> It will take around 20 seconds..",
    sec: 6,
  },
  {
    text: "We are extracting the most important concepts from your document </br> It will take around 20 seconds.. </br> Ok, job done.",
    sec: 6,
  },
  {
    text: "Let’s now generate some difficult questions..",
    sec: 14,
  },
  {
    text: "Let’s now generate some difficult questions.. </br> Not all of them, no worries",
    sec: 10,
  },
  {
    text: "It’s now time for the answers",
    sec: 8,
  },
  {
    text: "So you if you might get some of them wrong, you can always review it",
    sec: 10,
  },
  {
    text: "Please hold on the last seconds",
    sec: 10,
  },
  {
    text: "Please hold on the last seconds </br> 1000s of students are doing the same 😎",
    sec: 10,
  },
];

export default function LoaderSpinner(props) {

  const [loader_values, set_loader_values] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndextwo, setCurrentIndexTwo] = useState(0);

  useEffect(() => {
    set_loader_values(props.data);
  }, [props.data]);

  useEffect(() => {
    const progressBar = document.querySelector(".html");
    // if (progressBar) {
    var progressBarStyle = document.getElementById("progressBar");

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        // if (nextIndex < LoadingContent.length) {

        //   progressBarStyle.style.setProperty("--progress-value", "0"); // Reset progress value
        //   progressBarStyle.style.animation = "none"; // Reset animation

        //   //do nottttt remove below CMD ====================
        //   // eslint-disable-next-line no-unused-expressions
        //   progressBarStyle.offsetHeight;

        //   requestAnimationFrame(() => {
        //     progressBarStyle.style.animation = `html-progress ${LoadingContent[nextIndex].loadsec}s 1 forwards`;
        //   });
        // }
        return nextIndex;
      });
    }, LoadingContent[currentIndex].sec * 1000);

    if (currentIndex === LoadingContent.length - 1) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
    // }
  }, [currentIndex]);

  useEffect(() => {
    // var progressBarStyle = document.getElementById("progressBar");
    const interval = setInterval(() => {
      setCurrentIndexTwo((prevIndex) => {
        const nextIndex = prevIndex + 1;
        // progressBarStyle.style.setProperty("--progress-value", "0");
        // progressBarStyle.style.animation = "none"; // Reset animation
        //do nottttt remove below CMD ====================
        // eslint-disable-next-line no-unused-expressions
        // progressBarStyle.offsetHeight;
        requestAnimationFrame(() => {
          // progressBarStyle.style.animation = `html-progress 3s 1 forwards`;
        });
      });
    }, 3000);

    // if (currentIndex === 8) {
    //   clearInterval(interval);
    // }
  }, [currentIndextwo]);

  return (
    <>
      <section
        // style={loader_values ? { display: "block" } : { display: "none" }}
        style={loader_values ? { display: "block" } : { display: "none" }}
        className="loading-container"
      >
        {/* <div className="loading-gif-content">
          <img src={LoadingGif} alt="loading gif" />
        </div> */}
        <div className="loading-gif-content">
          <div className="childofload">
            {/* <div
              id="progressBar"
              className="progress-bar html"
              style={{
                // animation: `html-progress ${LoadingContent[currentIndex].sec}s 1 forwards`,
                animation: `html-progress 3s 1 forwards`,
              }}
            >
              <progress id="html" min="0" max="100" value="100"></progress>
            </div> */}
            <img src={smallSVG} alt="loading svg" />
            <div className="content-parent">
              {currentIndex < LoadingContent.length && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: LoadingContent[currentIndex].text,
                  }}
                >
                  {/* {LoadingContent[currentIndex].text} */}
                </div>
              )}
            </div>
          </div>
          <div style={{ position: "relative", top: "55px" }}>
            <img
              style={{ width: "auto", height: "20px" }}
              src={dendeNewLogo}
              alt="dende logo"
            />
          </div>
        </div>
      </section>
    </>
  );
}
