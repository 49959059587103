import "./LimitExceedPopup.scss";
import closeIcon from "../../assets/close.svg"
import { useNavigate } from "react-router";
import { useRef } from "react";

export default function LimitExceedPopup(){

    let navigate = useNavigate();
    const popupContainer = useRef();

    function closePopup(){
        popupContainer.current.style.display = "none";
    }

    return(
        <>
            <section ref={popupContainer} className="limit-exceed-popup-container">
                <div className="limit-exceed-popup-content">
                    <img onClick={()=>{closePopup()}} src={closeIcon} alt="close icon" className="close-popup-icon" />
                    <p className="text1">
                        In the free plan you have max. 3 Files
                    </p>
                    <p className="text2">
                        But you can upload as many doc as you want if you go to
                        Push Mode
                    </p>
                    <div className="text3">
                        <h6> 
                            <span style={{"color":"#e1501c"}}> 4,90$</span> for 7 days
                        </h6>
                        <p>(without the annoying automatic renewal)</p>
                    </div>
                    <div className="text4">
                        <h6 style={{"color":"#e1501c"}}> 
                            And you also get:
                        </h6>
                        <p>✅ All Questions (+300)</p>
                        <p>✅ All Flashcards</p>
                        <p>✅ All Topics</p>
                    </div>
                    <div className="button-container">
                        <button onClick={()=>{navigate("/signup")}}>Let's push!</button>
                    </div>
                    <div className="close-popup-link">
                        <p onClick={()=>{closePopup()}}>Go back to my Papers </p>
                    </div>
                </div>
            </section>
        </>
    )
}