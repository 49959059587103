import {combineReducers} from 'redux';
import { dataReducer } from './dataReducer';
import { userReducer } from './userReducer';
import { statsReducer } from './statsReducer';
import { usersReducer } from './usersReducer';
import { accountReducer } from './accountReducer';
import { activityReducer } from './activityReducer';

const reducers = combineReducers({
    master: dataReducer,
    user: userReducer,
    users: usersReducer,
    stats: statsReducer,
    account: accountReducer,
    activity: activityReducer
})

export default reducers;