import React, { useEffect, useState } from "react";
import "./Loading.scss";

const LoadingContent = [
  {
    text: "We are reading the entire file...",
    sec: 7,
  },
  {
    text: "We are reading the entire file... </br> page by page... ",
    sec: 3,
  },
  {
    text: "We are reading the entire file... </br> page by page... </br> Nah, it’s a joke",
    sec: 3,
  },
  {
    text: "We are extracting the most important </br> concepts from your document",
    sec: 7,
  },
  {
    text: "So you don’t miss anything before the exam",
    sec: 5,
  },
  {
    text: "Ok, we are almost there",
    sec: 4,
  },
  {
    text: "Generating super difficult questions..",
    sec: 5,
  },
  {
    text: "Generating super difficult questions.. </br> Not all of them, no worries",
    sec: 5,
  },
  {
    text: "Just a few seconds left, please hold on </br> 1000s  of students are doing the same",
    sec: 5,
  },
];

const Loading = () => {
  const [duration, setDuration] = useState(5);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // setTimeout(() => {
    //   setDuration(7);
    // }, 6000);
  }, []);

  const animationEnded = (e) => {
    const progressBar = document.querySelector(".html");
    // progressBar.style.setProperty("--progress-value", "0");
    // setDuration(0);
    // setTimeout(() => {
    //   setDuration(7);
    // }, 6000);
  };

  useEffect(() => {
    const progressBar = document.querySelector(".html");
    if (progressBar) {
      var progressBarStyle = document.getElementById("progressBar");
      // progressBarStyle.style.animation = 'none'

      // progressBar.style.setProperty("--progress-value", "0");
      const interval = setInterval(() => {
        // setCurrentIndex((prevIndex) => prevIndex + 1);

        // progressBar.className = "progress-bar html"
        // progressBarStyle.style.setProperty("--progress-value", "0"); // Reset progress value
        // progressBarStyle.style.animation = `none`; // Reset animation
        // progressBarStyle.offsetHeight; // Trigger reflow to apply reset styles
        // progressBarStyle.style.animation = `html-progress ${LoadingContent[currentIndex].sec}s 1 forwards`;
        // setTimeout(() => {
        //   progressBarStyle.style.animation = `html-progress ${LoadingContent[currentIndex].sec}s 1 forwards`;
        // }, 0);

        setCurrentIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex < LoadingContent.length) {
            progressBarStyle.style.setProperty("--progress-value", "0"); // Reset progress value
            progressBarStyle.style.animation = "none"; // Reset animation

            //do nottttt remove below CMD ====================
            // eslint-disable-next-line no-unused-expressions
            progressBarStyle.offsetHeight;
            
            requestAnimationFrame(() => {
              progressBarStyle.style.animation = `html-progress ${LoadingContent[nextIndex].sec}s 1 forwards`;
            });
            
          }
          return nextIndex;
        });
      }, LoadingContent[currentIndex].sec * 1000);

      if (currentIndex === LoadingContent.length - 1) {
        clearInterval(interval);
      }

      return () => clearInterval(interval);
    }
  }, [currentIndex]);

  return (
    <>
      <div>
        <p>example</p>
        <div className="main_login">
          <h2 className="login_title"></h2>
          <div>
            {/* <div className="progress-bar-container"> */}
            <div
              id="progressBar"
              className="progress-bar html"
              style={{
                animation: `html-progress ${LoadingContent[currentIndex].sec}s 1 forwards`,
              }}
            >
              <progress id="html" min="0" max="100" value="100"></progress>
            </div>

            <div className="content-parent">
              {currentIndex < LoadingContent.length && (
                <div
                dangerouslySetInnerHTML={{__html: LoadingContent[currentIndex].text}}
                >
                  {/* {LoadingContent[currentIndex].text} */}
                  </div>
              )}
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default Loading;
