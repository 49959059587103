import React, { useEffect, useState } from "react";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { FiTrash2, FiUpload } from "react-icons/fi";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import { BiLink } from "react-icons/bi";
import { SlDocs } from "react-icons/sl";
import { FaRegEdit } from "react-icons/fa";
import { Empty } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import "./Linkadd.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  createContent,
  deleteContentById,
  generate,
  generateFirst,
  masterRequest,
  updateURLContentById,
} from "../../helpers/requests";
import { ToastContainer, toast } from "react-toastify";
import { updateContentById } from "../../helpers/requests";

const Linkadd = () => {
  const delay = 1500;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urls = useSelector((state) => state.master.contents.url);
  const [allUrls, setAllUrls] = useState(urls);
  const [defaultURL, setDefaultURL] = useState({
    title: "",
    url: "",
    valid: true,
    saved: false,
  });

  const [newURLs, setNewURLs] = useState([]);
  const [loader, setLoader] = useState(false);

  const submit = async (index) => {
    try {
      let saveURL = {};
      if (index === -1) {
        saveURL = defaultURL;
      } else {
        saveURL = newURLs[index];
      }
      const docsLinkPattern =
        /^https?:\/\/drive\.google\.com\/file\/d\/([a-zA-Z0-9_-]+)/;


      if (docsLinkPattern.test(saveURL.url)) {
        let urlValidate = new URL(saveURL.url);
        let data = {
          title: saveURL.title,
          url: saveURL.url,
          type: "url",
          body: "",
          status: 1,
        };
        toast.success("Fetching file from drive. Please wait ....", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
        let response = await createContent({ dispatch, data });
        toast.dismiss();
        if (response.status) {
          toast.success("File downloaded!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: delay,
          });
          if (index !== -1) {
            let urlsList = newURLs.filter((note, i) => i !== index);
            setNewURLs(urlsList);
          }
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: delay,
          });
        }
      } else {
        toast.dismiss();
        toast.success(
          "Sure, more links coming soon. For now, just G Drive please.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: delay,
          }
        );
      }
    } catch (e) {
      toast.dismiss();
      toast.error("Invalid URL. Try adding http", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: delay,
      });
      let urlsList = [...newURLs];
      let currentURL = urlsList[index];
      currentURL = { ...currentURL, isValid: false };
      urlsList[index] = currentURL;
      setNewURLs(urlsList);
    }
  };

  const inputChanged = (event, index) => {
    if (index === -1) {
      setDefaultURL({ ...defaultURL, [event.target.name]: event.target.value });
    } else {
      let urlsList = [...newURLs];
      let changedURL = urlsList[index];
      changedURL = { ...changedURL, [event.target.name]: event.target.value };
      urlsList[index] = changedURL;
      setNewURLs(urlsList);
    }
  };

  const handleInputEdit = (event, index) => {
    let notesList = [...allUrls.data];
    let currentNote = notesList[index];
    currentNote = { ...currentNote, [event.target.name]: event.target.value };
    notesList[index] = currentNote;
    setAllUrls({ ...allUrls, data: notesList });
  };

  const deleteURL = async (url) => {
    let response = await deleteContentById({ dispatch, data: { id: url.id } });
    if (response.status) {
      setDefaultURL({
        title: "",
        url: "",
        valid: true,
        saved: false,
      });
      toast.success("URL Deleted!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: delay,
      });
    } else {
      toast.error("URL Not Deleted!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: delay,
      });
    }
  };

  const updateURL = async (id, index) => {
    const editURL = allUrls?.data[index];
    delete editURL["id"];
    let response = await updateURLContentById({
      dispatch,
      data: { id: id, url: { ...editURL } },
    });
    if (response.status) {
      toast.success("URL Updated!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: delay,
      });
    } else {
      toast.error("URL Not Updated!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: delay,
      });
    }
  };

  const generateQuestions = async () => {
    if (!urls.count) {
      toast.warning("Please add some urls first!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      return;
    }
    const availableNotes = urls.data.filter(
      (note) => note.questions_created != 1
    );
    if (!availableNotes.length) {
      toast.warning("All urls are processed", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      return;
    }
    setLoader(true);
    toast.success("Generating questions in background...", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
    });
    setTimeout(() => {
      toast.dismiss();
      navigate("/mcq");
      setLoader(false);
    }, 2000);
    let res = await generate({ dispatch, data: {} });
  };

  const addMoreURL = () => {
    setNewURLs([
      ...newURLs,
      {
        title: "",
        url: "",
        isValid: true,
      },
    ]);
  };

  useEffect(() => {
    const masterReq = async () => {
      const res = masterRequest({ dispatch });
    };
    masterReq();
  }, []);

  useEffect(() => {
    setAllUrls(urls);
  }, [urls]);

  return (
    <>
      <ToastContainer />
      <div className="main_linkadd">
        <div className="linkadd_content">
          <div className="left_link">
            <div className="top_sec">
              <h3 className="link_title">
                Add a text file from your Google Drive
              </h3>
              <p className="link_text">
                We accept Google drive links for now (many others soon).
              </p>
              <p className="link_text">
                Make sure that "everybody can see it" permission is enabled.
              </p>

              {/* <div className="link_main" id="style-2">
                <div className="link_card">
                  <div className="link_card_inner ">
                    <div>
                      <input
                        value={newURL}
                        type="text"
                        className="link_input"
                        placeholder="Give a title to your doc"
                        onChange={(e) => inputChanged(e)}
                      />
                    </div>
                    <div className="link_cont">
                      <BiLink className="icon" size={24} />
                      <input
                        value={newURL}
                        type="text"
                        className="link_input"
                        placeholder="Paste your link here"
                        onChange={(e) => inputChanged(e)}
                      />
                    </div>
                  </div>
                </div>

                {urls.data.map((url, index) => (
                  <div className="link_card">
                    <div className="link_card_inner" key={"url_" + index}>
                      <BiLink className="icon" size={24} />
                      <input
                        type="text"
                        value={url.url}
                        placeholder="Paste your link here"
                        className="link_input"
                        disabled
                        readOnly
                      />
                      <FiTrash2
                        className="icon"
                        size={24}
                        onClick={() => deleteURL(url)}
                      />
                    </div>
                  </div>
                ))}

                <div className="link_card link_card_add">
                  <div className="link_card_inner">
                    <div onClick={() => submit()}>
                      <div className="plus">
                        <AiOutlinePlus className="icon" />
                      </div>
                      <h5 className="add_text">Add another link</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="link_main"
                id="style-2"
                style={{ maxHeight: "auto", marginTop: "1.5rem" }}
              >
                <div className="link_cont">
                  <BiLink className="icon" size={24} />
                  <input
                    value={newURL}
                    type="text"
                    className="link_input"
                    placeholder="Paste your link here"
                    onChange={(e) => inputChanged(e)}
                  />
                </div>
              </div> */}
            </div>

            <div className="link_main">
              {urls?.count === 0 ? (
                <>
                  <div className="card_sec">
                    <div className="link_card">
                      <div className="link_cont">
                        <input
                          value={defaultURL.title}
                          type="text"
                          className="link_input"
                          name="title"
                          placeholder="Give a title to your doc"
                          onChange={(e) => inputChanged(e, -1)}
                        />

                        <div className="link_card_main">
                          <BiLink className="icon" size={24} />
                          <textarea
                            value={defaultURL.url}
                            type="text"
                            className="link_input"
                            name="url"
                            placeholder="Paste your link here"
                            onChange={(e) => inputChanged(e, -1)}
                          ></textarea>
                        </div>

                        <div className="link_card_footer">
                          <button className="button" onClick={() => submit(-1)}>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {allUrls?.data?.map((url, index) => (
                    <div className="card_sec">
                      <div className="link_card">
                        <div className="link_cont">
                          <input
                            value={url.title}
                            type="text"
                            className="link_input"
                            name="title"
                            placeholder="Give a title to your doc"
                            onChange={(e) => handleInputEdit(e, index)}
                          />

                          <div className="link_card_main">
                            <BiLink className="icon" size={24} />
                            <textarea
                              value={url.url}
                              type="text"
                              className="link_input"
                              name="url"
                              placeholder="Paste your link here"
                              onChange={(e) => handleInputEdit(e, index)}
                            ></textarea>
                          </div>

                          <div className="link_card_footer">
                            <button
                              className="save_btn"
                              onClick={() => updateURL(url.id, index)}
                            >
                              Update
                            </button>
                            <div
                              className="del_btn"
                              onClick={() => deleteURL(url)}
                            >
                              <FiTrash2 className="icon" size={22} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}

              {newURLs &&
                newURLs.length > 0 &&
                newURLs.map((newURL, index) => (
                  <div className="card_sec">
                    <div className="link_card">
                      <div className="link_cont">
                        <input
                          value={newURL.title}
                          type="text"
                          className="link_input"
                          name="title"
                          placeholder="Give a title to your doc"
                          onChange={(e) => inputChanged(e, index)}
                        />

                        <div className="link_card_main">
                          <BiLink className="icon" size={24} />
                          <textarea
                            value={newURL.url}
                            type="text"
                            className="link_input"
                            name="url"
                            placeholder="Paste your link here"
                            onChange={(e) => inputChanged(e, index)}
                          ></textarea>
                        </div>

                        <div className="link_card_footer">
                          <button
                            className="button"
                            onClick={() => submit(index)}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="card_sec">
                <div className="link_card_add" onClick={addMoreURL}>
                  <div className="plus">
                    <AiOutlinePlus className="icon" />
                  </div>
                  <h5 className="card_title"> Add another link </h5>
                </div>
              </div>
            </div>
            <div className="bottom_sec">
              <h3 className="link_title">Add more material</h3>

              <div className="btn_sec">
                <Link
                  style={{ textDecoration: "none", color: "unset" }}
                  to="/uploadfile"
                >
                  <button className="btn_upload">
                    <SlDocs className="icon" /> Upload files
                  </button>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "unset" }}
                  to="/noteadd"
                >
                  <button className="btn_upload">
                    <FaRegEdit className="icon" /> Paste your text
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="material_bottom">
          <Link
            style={{ textDecoration: "none", color: "unset" }}
            to="/material"
          >
            <div className="back">
              <AiOutlineLeft /> Back to papers
            </div>
          </Link>
          <div className="total">{`${urls.count} link${
            urls.count != 1 ? "s" : ""
          } added`}</div>
          <button
            className="button"
            style={{ height: "40px", width: "250px" }}
            disabled={loader}
            onClick={() => generateQuestions()}
          >
            {loader ? "Generating questions..." : "Generate questions"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Linkadd;
