import { useEffect } from "react";

export default function WaveSVG(props) {
  // var svgValues = props.data;

  return (
    <>
      <svg
        style={{ borderRadius: "13px" }}
        width="100%"
        height="100%"
        viewBox="0 0 1000 1000"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        overflow="auto"
        shapeRendering="auto"
        fill="#ffffff"
      >
        <defs>
          <path
            id="wavepath"
            d="M 0 2000 0 500 Q 100 350 200 500 t 200 0 200 0 200 0 200 0 200 0 200 0 200 0  v1000 z"
          />
          <path id="motionpath" d="M -400 0 0 0" />
        </defs>
        <g>
          <use
            xlinkHref="#wavepath"
            y={props.data}
            fill="#fff234"
          >
            <animateMotion dur="3s" repeatCount="indefinite">
              <mpath xlinkHref="#motionpath" />
            </animateMotion>
          </use>
        </g>
      </svg>
    </>
  );
}
