import React, { useState } from 'react'
import { BsEnvelopeCheck } from 'react-icons/bs';
import { RxCross1 } from 'react-icons/rx';
import { Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import './Sendus.scss'
const Sendus = () => {

    const [sendval, setSendval] = useState({
        name: '',
        email: '',
        message: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setSendval((preval) => ({
            ...preval,
            [name]: value,
        }));
    }


    const oncheck = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    return (
        <div className='main_send'>
            <div className="message_content">
                <div className="close">
                    <RxCross1 />
                </div>
                <div className="message_material">

                    <h5 className="title">
                        Send us an email
                    </h5>
                    <div className="feed_form">
                        <input value={sendval.name} onChange={handleChange} name='name' className='feed_input' type={'text'} placeholder='Your Name' />
                        <input value={sendval.email} onChange={handleChange} name='email' className='feed_input' type={'text'} placeholder=' Your E-mail (we reply here)' />
                        <textarea
                        value={sendval.message}
                            onChange={handleChange}
                            name='message'
                            cols={18}
                            rows={13}
                            placeholder='Write your message here..'
                        />
                    </div>
                    <Checkbox style={{ marginTop: '20px' }} onChange={oncheck}>I accept <Link to={'/privacy'}>Privacy Policy</Link> and <Link to={'/terms'}>T&C</Link></Checkbox>
                    <Link style={{ textDecoration: 'none', margin: '20px auto' }} to='/message'>
                        <button className='button'>Send email</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Sendus
