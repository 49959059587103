import { actionTypes } from "../constants/action-types";

const initialState = {
  data: null,
  questions: {
    flashcard: {
      total: 0,
      reviewed: 0,
      questions: [],
      attempted: []
    },
    MCQ: {
      total: 0,
      reviewed: 0,
      questions: [],
      attempted: []
    },
    open: {
      total: 0,
      reviewed: 0,
      questions: [],
      attempted: []
    }
  },
  contents: {
    file: {
      count: 0,
      data: []
    },
    url: {
      count: 0,
      data: []
    },
    note: {
      count: 0,
      data: []
    }
  },
  chapters: []
}

export const dataReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case actionTypes.MASTER_REQ:
      let contents = {
        file: {
          count: 0,
          data: []
        },
        url: {
          count: 0,
          data: []
        },
        note: {
          count: 0,
          data: []
        }
      }
      let questions = {
        flashcard: {
          total: 0,
          reviewed: 0,
          questions: [],
          attempted: []
        },
        MCQ: {
          total: 0,
          reviewed: 0,
          questions: [],
          attempted: []
        },
        open: {
          total: 0,
          reviewed: 0,
          questions: [],
          attempted: []
        }
      }
      let contentMap = {}
      let questionMap = {}
      let chapterQuestions = {}

      payload?.data?.contents?.forEach(content => {
        contentMap[content.id] = content.title;
        contents[content.type].count++;
        contents[content.type].data.push(content);
      })


      payload?.data?.questions?.forEach(question => {

        if (!questionMap[question.chapter_id]) {
          questionMap[question.chapter_id] = 0
        }

        if (!chapterQuestions[question.chapter_id]) {
          chapterQuestions[question.chapter_id] = []
        }

        chapterQuestions[question.chapter_id].push(question)
        questionMap[question.chapter_id]++;
        questions[question.type].total++;


        if (question.solution_answer != null || question.solution_answer_text != null || question.draft != null) {
          questions[question.type].reviewed++;
          questions[question.type].attempted.push(question);
        } else {
          questions[question.type].questions.push(question);
        }
      })

      const chapters = payload?.data?.chapters?.map(chapter => {
        return { ...chapter, content: contentMap[chapter.content_id], questions: chapterQuestions[chapter.id] ? chapterQuestions[chapter.id] : [], questionsCount: questionMap[chapter.id] ? questionMap[chapter.id] : 0 }
      });
      return { ...state, data: payload.data, questions: questions, contents: contents, chapters: chapters };
    default:
      return state;
  }
};
