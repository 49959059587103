import React, { useEffect, useState } from "react";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { FiTrash2, FiUpload } from "react-icons/fi";
import { AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import { BiLink, BiArrowToTopRight } from "react-icons/bi";
import { SlDocs } from "react-icons/sl";
import { FaRegEdit } from "react-icons/fa";
import { BsArrowsAngleExpand, BsX } from "react-icons/bs";
import { Empty } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import "./Noteadd.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  createContent,
  deleteContentById,
  generate,
  updateContentById,
} from "../../helpers/requests";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";

const Noteadd = () => {
  const delay = 1500;
  const dispatch = useDispatch();
  const notes = useSelector((state) => state.master.contents.note);
  const [allNotes, setAllNotes] = useState(notes);
  const [noteIndex, setNoteIndex] = useState(null);
  const [newNotes, setNewNotes] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [defaultNote, setDefaultNote] = useState({
    title: "",
    note: "",
    saved: false,
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const submit = async (index) => {
    let currentNote = {};
    if (index === -1) {
      currentNote = defaultNote;
    } else {
      currentNote = newNotes[index];
    }

    if (
      currentNote &&
      currentNote.title &&
      currentNote.title.length &&
      currentNote.para &&
      currentNote.para.length
    ) {
      toast.success("Adding note...", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
      let data = {
        title: currentNote.title,
        url: currentNote.title,
        type: "note",
        body: currentNote.para,
        status: 1,
      };
      let response = await createContent({ dispatch, data });
      toast.dismiss();
      if (response.status) {
        toast.success("Note added!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: true,
          autoClose: delay,
        });
        if (index !== -1) {
          let notesList = newNotes.filter((note, i) => i !== index);
          setNewNotes(notesList);
        }
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: delay,
        });
      }
    } else {
      toast.error("Provide a title and description of your note", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        autoClose: delay,
      });
    }
  };

  const inputChanged = (event, index) => {
    if (index === -1) {
      setDefaultNote({
        ...defaultNote,
        [event.target.name]: event.target.value,
      });
    } else {
      let notesList = [...newNotes];
      let currentNote = notesList[index];
      currentNote = { ...currentNote, [event.target.name]: event.target.value };
      notesList[index] = currentNote;
      setNewNotes(notesList);
    }
  };

  const handleInputEdit = (event, index) => {
    let notesList = [...allNotes.data];
    let currentNote = notesList[index];
    currentNote = { ...currentNote, [event.target.name]: event.target.value };
    notesList[index] = currentNote;
    setAllNotes({ ...allNotes, data: notesList });
  };

  const addMoreNotes = () => {
    setNewNotes([
      ...newNotes,
      {
        title: "",
        para: "",
        saved: false,
      },
    ]);
  };

  const handleNoteChange = async (event) => {
    const currentNote = allNotes.data[noteIndex];
    currentNote.body = event.target.value;
    let notesList = [...allNotes.data];
    notesList[noteIndex] = currentNote;
    setAllNotes({ ...allNotes, data: notesList });
    const id = currentNote.id;
    delete currentNote["id"];
    let response = await updateContentById({
      dispatch,
      data: { id, note: currentNote },
    });
  };

  const updateNote = async (id, index) => {
    const editNote = allNotes.data[index];
    delete editNote["id"];
    let response = await updateContentById({
      dispatch,
      data: { id, note: { ...editNote } },
    });
    if (response.status) {
      toast.success("Note Updated!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        autoClose: delay,
      });
    } else {
      toast.error("Note Was Not Updated!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        autoClose: delay,
      });
    }
  };

  const deleteNote = async (text) => {
    let response = await deleteContentById({ dispatch, data: { id: text.id } });
    if (response.status) {
      toast.success("Note Deleted!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        autoClose: delay,
      });
    } else {
      toast.error("Note Was Not Deleted!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        autoClose: delay,
      });
    }
  };
  const navigate = useNavigate();

  const generateQuestions = async () => {
    if (!notes.count) {
      toast.warning("Please add some notes first!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      return;
    }
    const availableNotes = notes.data.filter(
      (note) => note.questions_created != 1
    );
    if (!availableNotes.length) {
      toast.warning("All notes are processed", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      return;
    }
    setLoader(true);
    toast.success("Generating questions in background...", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
    });
    setTimeout(() => {
      toast.dismiss();
      navigate("/mcq");
      setLoader(false);
    }, 2000);
    let res = await generate({ dispatch, data: {} });
  };

  useEffect(() => {
    setAllNotes(notes);
  }, [notes]);

  return (
    <>
      <ToastContainer />
      <div className="main_noteadd">
        <div className="note_content">
          <div className="left_note">
            <div className="top_sec">
              <h3 className="note_title">Add your notes</h3>
              <div className="top_sec_content">
                {notes?.count === 0 ? (
                  <>
                    <div className="note_card">
                      <input
                        value={defaultNote.title}
                        type="text"
                        name="title"
                        placeholder="Give a title to your note"
                        onChange={(e) => inputChanged(e, -1)}
                      />
                      <div className="note_group">
                        <textarea
                          value={defaultNote.para}
                          cols={34}
                          rows={8}
                          name="para"
                          placeholder="Paste or write your text here..."
                          onChange={(e) => inputChanged(e, -1)}
                        />
                      </div>
                      <div className="note_footer">
                        <button className="save_btn" onClick={() => submit(-1)}>
                          Save
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {allNotes?.data?.map((note, index) => (
                      <div className="note_card" key={"text_card_" + note.id}>
                        <input
                          value={note.title}
                          type="text"
                          name="title"
                          placeholder="Give a title to your note"
                          onChange={(e) => {
                            handleInputEdit(e, index);
                          }}
                        />
                        <div className="note_group">
                          <div className="text_area">
                            <textarea
                              value={note.body}
                              cols={34}
                              rows={8}
                              name="body"
                              placeholder="Paste or  write your text here..."
                              onChange={(e) => {
                                handleInputEdit(e, index);
                              }}
                            />
                          </div>
                          <div className="expand_icon" style={{cursor: "pointer"}}>
                            <BsArrowsAngleExpand
                              onClick={() => {
                                setNoteIndex(index);
                                openModal();
                              }}
                              size={22}
                            />
                          </div>
                        </div>
                        <div className="note_footer">
                          <button
                            className="save_btn"
                            onClick={() => updateNote(note.id, index)}
                          >
                            Update
                          </button>
                          <div
                            className="del_btn"
                            style={{cursor: "pointer"}}
                            onClick={() => deleteNote(note)}
                          >
                            <FiTrash2 className="icon" size={22} />
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {newNotes &&
                  newNotes.length > 0 &&
                  newNotes.map((note, index) => (
                    <div className="note_card">
                      <input
                        value={note.title}
                        type="text"
                        name="title"
                        placeholder="Give a title to your note"
                        onChange={(e) => inputChanged(e, index)}
                      />
                      <div className="note_group">
                        <textarea
                          value={note.para}
                          cols={34}
                          rows={8}
                          name="para"
                          placeholder="Paste or write your text here..."
                          onChange={(e) => inputChanged(e, index)}
                        />
                      </div>
                      <div className="note_footer">
                        <button
                          className="save_btn"
                          onClick={() => submit(index)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ))}

                <div className="note_card">
                  <div className="add_more_card" onClick={() => addMoreNotes()}>
                    <div className="plus">
                      <AiOutlinePlus className="icon" size={24} />
                    </div>
                    <h5 className="add_text">add new text</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="bottom_sec">
              <h3 className="link_title">Add more material</h3>

              <div className="btn_sec">
                <Link
                  style={{ textDecoration: "none", color: "unset" }}
                  to="/uploadfile"
                >
                  <button className="btn_upload">
                    <SlDocs className="icon" /> Upload files{" "}
                  </button>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "unset" }}
                  to="/linkadd"
                >
                  <button className="btn_upload">
                    <FaRegEdit className="icon" /> From Link{" "}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="material_bottom">
          <Link
            style={{ textDecoration: "none", color: "unset" }}
            to="/material"
          >
            <div className="back">
              <AiOutlineLeft /> Back to papers
            </div>
          </Link>
          <div className="total">{`${notes.count} note${
            notes.count != 1 ? "s" : ""
          } added`}</div>
          <button
            className="button"
            style={{ height: "40px", width: "250px" }}
            disabled={loader}
            onClick={() => generateQuestions()}
          >
            {loader ? "Generating questions..." : "Generate questions"}
          </button>
        </div>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          className="modal"
          overlayClassName="modal-overlay"
        >
          <div className="modal_content">
            <div className="modal_header">
              <h1>{allNotes.data[noteIndex]?.title}</h1>
              <div className="close_icon" onClick={closeModal}>
                <BsX size={30} />
              </div>
            </div>
            <div className="modal_body">
              <textarea
                className="textarea"
                value={allNotes.data[noteIndex]?.body}
                placeholder="Paste or write your text here..."
                style={{
                  width: "100%",
                  height: "100%",
                  resize: "none",
                  overflow: "auto",
                  boxSizing: "border-box",
                  outline: "none",
                  border: "none",
                }}
                onChange={(e) => handleNoteChange(e)}
              />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Noteadd;
