import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Congratulation.scss'



const Congratulation = () => {



    const containerRef = useRef(null);
    let confettiInterval = null;

    const confettiFrequency = 3;
    const confettiColors = ['#EF2964', '#00C09D', '#2D87B0', '#48485E', '#EFFF1D'];
    const confettiAnimations = ['slow', 'medium', 'fast'];

    useEffect(() => {
        setupElements();
        renderConfetti();

        return () => {
            clearInterval(confettiInterval);
        };
    }, []);

    const setupElements = () => {
        const containerEl = containerRef.current;
        const elPosition = containerEl.style.position;

        if (elPosition !== 'relative' && elPosition !== 'absolute') {
            containerEl.style.position = 'relative';
        }

        containerEl.classList.add('confetti-container');
    };

    const renderConfetti = () => {
        confettiInterval = setInterval(() => {
            const confettiEl = document.createElement('div');
            const confettiSize = (Math.floor(Math.random() * 3) + 7) + 'px';
            const confettiBackground = confettiColors[Math.floor(Math.random() * confettiColors.length)];
            const confettiLeft = (Math.floor(Math.random() * containerRef.current.offsetWidth)) + 'px';
            const confettiAnimation = confettiAnimations[Math.floor(Math.random() * confettiAnimations.length)];

            confettiEl.classList.add('confetti', 'confetti--animation-' + confettiAnimation);
            confettiEl.style.left = confettiLeft;
            confettiEl.style.width = confettiSize;
            confettiEl.style.height = confettiSize;
            confettiEl.style.backgroundColor = confettiBackground;

            confettiEl.removeTimeout = setTimeout(function () {
                confettiEl.parentNode.removeChild(confettiEl);
            }, 3000);

            containerRef.current.appendChild(confettiEl);
        }, 25);
    };

    return (
        <div>
            <div className="js-container container" style={{ top: '0px !important' }} ref={containerRef}></div>

            <div className='congrat_content' style={{ textAlign: 'center', marginTop: '150px', position: 'fixed', width: '100%', height: '100%', top: '0px', left: '0px' }}>
                <div className="checkmark-circle">
                    <div className="background"></div>
                    <div className="checkmark draw"></div>
                </div>
                <div className='well_text'>Well done you completed the quiz</div>
                <div className='sub_well_text'>Visit home to load more questions.</div>
                <Link to={'/aloha'} style={{textDecoration:'none'}} >
                <button className="button_home">Back To Home</button>
                </Link>
            </div>
        </div>
    );
};

export default Congratulation;
