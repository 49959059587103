import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  return (
    <>
    </>
    // <div className="main_footer">
    //   <div className="top_footer">
    //     <div className="title">
    //       What questions the professor <br />
    //       would make you to get you in trouble?
    //     </div>
    //     <div className="para">Better to figure out before the exam day</div>
    //     <Link style={{ textDecoration: "none" }} to="/material">
    //       <button className="button">Generate questions now</button>
    //     </Link>
    //   </div>

    //   <div className="bottom_footer">
    //     <div className="top_bottom_footer">
    //       <div className="left_bottom">
    //         <div className="logo">dende</div>
    //         <div className="address">
    //           Corso Buenos Aires, 23 <br />
    //           20124 - Milan (MI)
    //         </div>
    //       </div>
    //       <div className="right_bottom">
    //       </div>
    //     </div>
    //   </div>

    //   <div className="location">
    //     dende All Rights Reserved Copyright@2023 |{" "}
    //     <Link
    //       style={{ textDecoration: "none", color: "white" }}
    //       to={"/privacy"}
    //     >
    //       Privacy Policy
    //     </Link>{" "}
    //     |{" "}
    //     <Link style={{ textDecoration: "none", color: "white" }} to={"/terms"}>
    //       Terms & Conditions
    //     </Link>
    //   </div>
    // </div>
  );
};

export default Footer;
