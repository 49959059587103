import React, { useEffect, useState, useRef } from "react";
import { FiHelpCircle } from "react-icons/fi";
import Filter from "../../Components/Filter/Filter";
import "./MCQ.scss";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  masterQuestionsRequest,
  masterRequest,
  reviewMCQ,
} from "../../helpers/requests";
import { AiOutlineRight } from "react-icons/ai";
import Reply from "../Reply/Reply";
import QuestionFilter from "../../Components/QuestionFilter/QuestionFilter";

const MCQ = () => {
  const { id, cid } = useParams();
  let delay = 1500;
  let delay_new = 2000;
  const progressBarContainer = useRef();
  const progressBar = useRef();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const contents = useSelector((state) => state.master.data?.contents || null);
  const masterQuestions = useSelector(
    (state) => state.master.data?.questions || []
  );
  const mcqs = useSelector((state) => state.master.questions.MCQ);
  const open = useSelector((state) => state.master.questions.open);
  const [questions, setQuestions] = useState([]);
  const [answerText, setAnswerText] = useState("");
  const [questionIndex, setQuestionIndex] = useState(0);
  const [totalIndex, setTotalIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [lock, setLock] = useState(false);
  const [popup, setPopup] = useState(false);
  const [questionTypes, setQuestionTypes] = useState(null);
  const [fileIds, setFileIds] = useState(null);
  const [files, setFiles] = useState({});
  const [skip, setSkip] = useState(0);
  const [duplicateQuestions, setDuplicateQuestions] = useState([]);


  const CustomToast = ({ message }) => {
    return (
      <div className="custom-toast">
        <center>
          <h3>Correct!</h3>
        </center>
        <br/>
        <center>
          <p>Dende's goal is to increase the student's knowledge score for all fundamental.</p>
        </center>
      </div>
    );
  };

  const handleQuestionClick = async (option) => {
    if (!lock) {
      let newOption = questionIndex + "_option_" + (option + 1);
      setSelectedOption(newOption);
      if (questionIndex + "_" + questions[questionIndex].answer == newOption) {
        setLock(true);
        progressBarContainer.current.style.visibility = "visible";
        progressBarContainer.current.style.transition = `width ${delay_new}ms`;
        progressBar.current.style.width = "0%";
        // toast.success("Loading next question...", {
        //   position: toast.POSITION.TOP_CENTER,
        //   autoClose: delay / 2,
        // });
        let question = questions[questionIndex];
        await reviewMCQ({
          dispatch,
          data: {
            question_id: question.id,
            answer: selectedOption,
            type: question.type,
            answer_text: answerText,
            draft: "",
            status: 1,
          },
        });
        setTimeout(async () => {
          if ((questionIndex + 1) % totalIndex == 0) {

            const res = await masterQuestionsRequest({
              dispatch,
              lastId: masterQuestions[masterQuestions?.length - 1]?.id,
            });

            const newQuestions = [...res?.data?.questions];

            const mergedArray = newQuestions.map((obj1) => {
              const matchingObj = questions?.find(
                (obj2) => obj2.id === obj1.id
              );
              return { ...obj1, ...matchingObj };
            });

            setQuestions(mergedArray);
            setTotalIndex(mergedArray.length);
            let index = questionIndex + 1;
            setQuestionIndex(index++);
            setSelectedOption(null);
            setAnswerText("");
            setLock(false);
            setPopup(false);
          } else {
            progressBarContainer.current.style.visibility = "hidden";
            progressBar.current.style.width = "100%";
            let index = questionIndex + 1;
            setQuestionIndex(index);
            setSelectedOption(null);
            setAnswerText("");
            setLock(false);
            setPopup(false);
          }
        }, delay_new);
      }
    }
  };

  const saveAnswer = async (answer) => {
    if (!lock) {
      if (answer && answer.length) {
        setLock(true);
        toast.success("Loading next question...", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: delay / 2,
        });
        let question = questions[questionIndex];
        await reviewMCQ({
          dispatch,
          data: {
            question_id: question.id,
            answer: null,
            type: question.type,
            answer_text: answer,
            draft: null,
            status: 1,
          },
        });
        setTimeout(async () => {
          setQuestionIndex((questionIndex + 1) % totalIndex);
          setSelectedOption(null);
          setAnswerText("");
          setLock(false);
          setPopup(false);
        }, delay);
      }
    }
  };

  const saveDraft = async (text) => {
    let question = questions[questionIndex];
    questions[questionIndex].draft = text;
    await reviewMCQ({
      dispatch,
      data: {
        question_id: question.id,
        answer: null,
        type: question.type,
        answer_text: null,
        draft: text,
        status: 1,
      },
    });
  };

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }
    return array;
  };

  useEffect(() => {
    let total = 0;
    let mcqKey = "questions";
    let openKey = "questions";
    if (mcqs.total > 0 && mcqs.questions.length == 0) {
      total = mcqs.attempted.length;
      mcqKey = "attempted";
    } else {
      total = mcqs.questions.length;
    }

    if (open.total > 0 && open.questions.length == 0) {
      total = total + open.attempted.length;
      openKey = "attempted";
    } else {
      total = total + open.questions.length;
    }
    let sortedQuestions = [...open[openKey], ...mcqs[mcqKey]]; //.sort((a, b) => a.chapter_id-b.chapter_id);
    if (id) {
      sortedQuestions = masterQuestions.filter(
        (question) => question.id + "" === "" + id
      );
    } else if (cid) {
      sortedQuestions = masterQuestions.filter(
        (question) =>
          question.chapter_id + "" === "" + cid && question.type !== "flashcard"
      );
    } else {
      if (questionTypes) {
        sortedQuestions = sortedQuestions.filter(
          (question) => questionTypes.indexOf(question.type) > -1
        );
      }
      if (fileIds) {
        sortedQuestions = sortedQuestions.filter(
          (question) => fileIds.indexOf(question.content_id) > -1
        );
      }
    }
    // if (!isShuffled && sortedQuestions.length > 0) {
    shuffle(sortedQuestions);
    //   setIsShuffled(true);
    // }
    setQuestions(sortedQuestions);
    setDuplicateQuestions(sortedQuestions);
    setQuestionIndex(0);
    setTotalIndex(sortedQuestions.length);
  }, [mcqs, open]);

  useEffect(() => {
    let total = 0;
    let sortedQuestions = duplicateQuestions; //.sort((a, b) => a.chapter_id-b.chapter_id);
    if (id) {
      sortedQuestions = masterQuestions.filter(
        (question) => question.id + "" === "" + id
      );
    } else if (cid) {
      sortedQuestions = masterQuestions.filter(
        (question) =>
          question.chapter_id + "" === "" + cid && question.type !== "flashcard"
      );
    } else {
      if (questionTypes) {
        sortedQuestions = sortedQuestions.filter(
          (question) => questionTypes.indexOf(question.type) > -1
        );
      }
      if (fileIds) {
        sortedQuestions = sortedQuestions.filter(
          (question) => fileIds.indexOf(question.content_id) > -1
        );
      }
    }
    setQuestions(sortedQuestions);
    setQuestionIndex(0);
    setTotalIndex(sortedQuestions.length);
  }, [questionTypes, fileIds]);

  useEffect(() => {
    let _files = {};
    if (contents && contents.length) {
      contents.forEach((content) => {
        _files[content.id] = content.language;
      });
      setFiles(_files);
    }
  }, [contents]);

  useEffect(() => {
    const masterQuestionsReq = async () => {
      const res = await masterRequest({ dispatch });
    };
    masterQuestionsReq();
  }, [skip]);

  return (
    <>
      <ToastContainer />
      {popup && (
        <Reply
          totalIndex={totalIndex}
          questionIndex={questionIndex}
          question={questions[questionIndex]}
          setPopup={(e) => setPopup(e)}
          saveDraft={(e) => saveDraft(e)}
          saveAnswer={(e) => saveAnswer(e)}
          setQuestionIndex={setQuestionIndex}
        />
      )}
      {!popup && (
        <div
          className={
            questions.length == 0
              ? "main_mcq"
              : questions[questionIndex].type == "MCQ"
                ? "main_mcq"
                : "main_qeust"
          }
        >
          <Filter
            question={questions[questionIndex]}
            setFileIds={(e) => setFileIds(e)}
            setQuestionTypes={(e) => setQuestionTypes(e)}
          />
          <QuestionFilter
            questions={questions}
            index={questionIndex}
            setQuestionIndex={(e) => setQuestionIndex(e)}
          />

          {questions.length == 0 ? (
            <div className="main_carousel">
              <div className="card">
                <p className="title" style={{ fontSize: "20px" }}>
                  There are no questions are available at the moment. Please
                  create some by adding your
                  <Link style={{ textDecoration: "none" }} to="/material">
                    {" "}
                    papers{" "}
                  </Link>
                </p>
              </div>
            </div>
          ) : questions[questionIndex].type == "MCQ" ? (
            <>
              <h6 className="mcq_title">
                {questions[questionIndex].statement}
              </h6>
              <div className="main_option">
                {new Array(4).fill(0).map((val, index) => {
                  return (
                    <div
                      className="card"
                      key={"options_" + (index + 1)}
                      style={{
                        backgroundColor: selectedOption
                          ? questionIndex + "_option_" + (index + 1) ===
                            selectedOption &&
                            questionIndex +
                            "_" +
                            questions[questionIndex].answer ===
                            selectedOption
                            ? "#C1FF72"
                            : questionIndex + "_option_" + (index + 1) ===
                              selectedOption
                              ? "#ff7272"
                              : "white"
                          : "white",
                      }}
                      onClick={() => handleQuestionClick(index)}
                    >
                      {questions[questionIndex]["option_" + (index + 1)]}
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="question_content">
              <div className="card">
                <h4 className="title" style={{ fontWeight: 700 }}>
                  {questions[questionIndex].statement}
                </h4>
              </div>
              <div className="question_content_footer">
                <button
                  className="button"
                  onClick={() => setPopup(true)}
                  style={{ width: "150px" }}
                >
                  Reply
                </button>
                <span
                  className="may"
                  onClick={() =>
                    setQuestionIndex((questionIndex + 1) % totalIndex)
                  }
                >
                  not now, move on <AiOutlineRight />{" "}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      <section ref={progressBarContainer} className="progress-bar-container">
          <div ref={progressBar} className="progress-bar">
          </div>
          <div className="progress-bar-content">
              <h3>Correct!</h3>
              <p>Denotes the size of the circle radius or the semi-major and semi-minor axes of the ellipse. As absolute length it can be expressed in any unit allowed by the CSS data type. Percentages for the horizontal axis refer to the width of the box.</p>
          </div>
      </section>
    </>
  );
};

export default MCQ;
