import React, { useRef, useImperativeHandle, forwardRef, useContext } from 'react';
import { toast } from "react-toastify";
import { CurrentUserInfo } from "../../Components/ContextStore/ContextStore";
import {
    ExamSpaceUploadResource,
    GetUserInfoAPI,
} from "../../helpers/requests";


function FileUploadComponent(props, ref) {
	const fileInputRef = useRef(null);
    const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
    const global_default_examspace_id = useRef("");	
	var current_domain = window.location.origin;
	var upload_file_error_text = `<p>You can have only 1 document at a time in free mode.</p> <a href="${current_domain}/pushmode"><p style="color: #f18f01;">Go push mode to go unlimited</p></a>`;
	var delete_file_error_text = `<p>You will not be able to upload any other document as you reach your limit of 3 for the free mode.</p> <a href="${current_domain}/pushmode"><p style="color: #f18f01;">Go push mode to go unlimited</p></a>`;
	
	useImperativeHandle(ref, function() {
		return {
			triggerFileInput: function() {
				fileInputRef.current.click();
			}
		};
	});
	
    async function getDefaultExamspaceIdUtility(){
        return new Promise( async(resolve, reject) => {
            let default_examspace_id = "";
            if( Object.keys(sharedUserInfo).length > 0){
                default_examspace_id = sharedUserInfo.default_examspace_id;
                global_default_examspace_id.current = sharedUserInfo.default_examspace_id;
                resolve(default_examspace_id);
            } 
            else {
                let getResponse = await GetUserInfoAPI()
                if (getResponse.status) {
                    if(getResponse.data.length > 0){
                        if(Object.keys(getResponse.data[0]).length > 0){
                            setSharedUserInfo(getResponse.data[0]);
                            default_examspace_id = getResponse.data[0]["default_examspace_id"];
                            global_default_examspace_id.current = getResponse.data[0]["default_examspace_id"];
                            resolve(default_examspace_id);
                        }
                    }
                }
            }
            
        });
    } 

	async function handleFileUpload(e) {
		let temp_response = await getDefaultExamspaceIdUtility();
		console.log('props.examspaceId', props.examspaceId)
		console.log('global_default_examspace_id.current', global_default_examspace_id.current)
		try {
			const userUploadedDoc = e.target.files[0];
			if (userUploadedDoc && userUploadedDoc.type === "application/pdf") {
				if(Math.round(userUploadedDoc.size / (1000 * 1000)) < 40){
					toast.success("File uploading...", {
						position: toast.POSITION.TOP_CENTER,
						autoClose: false,
					});
					const response = await ExamSpaceUploadResource(userUploadedDoc, global_default_examspace_id.current);
					if (response.status === true) {
						toast.dismiss();
						if(response.message.toLowerCase() === "file upload success"){
							toast.success("File uploaded successfully", {
								position: toast.POSITION.TOP_CENTER,
							});
						} else {
							toast.error(response.message, {
								position: toast.POSITION.TOP_CENTER,
							});
						}
						props.onUploadSuccess(response);
					} else if (response.status === false) {
						toast.dismiss();
						if(response.message === "In free plan you can't upload more than 1 file at a time"){
							toast.error(
								<div dangerouslySetInnerHTML={{ __html: upload_file_error_text }} />,
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
						} else if(response.message === "In free plan you can't upload more than 3 files"){
							toast.error(
								<div dangerouslySetInnerHTML={{ __html: delete_file_error_text }} />,
								{
									position: toast.POSITION.TOP_CENTER,
								}
							);
						} else {
							toast.warning(response.message, {
								position: toast.POSITION.TOP_CENTER,
							}); 					
						}
					}				
				} else {
					toast.error("Oops, your file is too large. Please upload max 40MB files", {
						position: toast.POSITION.TOP_CENTER,
					});				
				}
			} else {
				toast.error("Only .pdf format are supported at the moment", {
					position: toast.POSITION.TOP_CENTER,
				});      
			}
		} catch (error) {
			console.error(error)
		} finally {
			fileInputRef.current.value = "";
		}    
		
	}
		
	return (
		<input type="file" ref={fileInputRef} hidden accept=".pdf" onChange={(e)=>{handleFileUpload(e)}}/>
	);
}
		
export default forwardRef(FileUploadComponent);