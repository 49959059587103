import React from 'react'
import './Hero.scss'
import { useEffect } from "react";
import { getApiUrl } from '../../../../helpers/requests';
import { Link, useLocation, useNavigate } from "react-router-dom";



const Hero = () => {
  var apiUrl = getApiUrl();


  useEffect(()=>{
    if(apiUrl === "https://app.dende.ai/api"){
      window.location.replace("https://dende.ai/");
    }
    // if(apiUrl === "https://release.dendedev.it/api"){
    //   window.location.replace("https://release.dendedev.it/login");
    // }
  },[]);


  const cards = [
    {
      id: 1,
      title: 'Upload your study material',
      // , .ePub, .doc, .ppt,
      description: <p>PDF<br /> URLs and personal notes</p>
    },
    {
      id: 2,
      title: 'Generate Quizzes',
      description: <div>MCQs and Open-ended Questions.<br /> Like a professor would ask.</div>
    },
    {
      id: 3,
      title: 'Review correct answers',
      description: <div>Get feedback and use flashcards<br /> to memorize concepts.</div>
    }
  ]

  return (
    <>
      <Link to="/login">Login</Link>
    </>
    // <div className='main_hero'>
    //   <h1 className="title">
    //     Prepare for your next exam with Dende
    //   </h1>
    //   <div className="main_card">
    //     {
    //       cards !== undefined && cards.length > 0 && cards.map(item => (
    //         <div className="card" key={item.id}>
    //           <h2 className="card_title">{item.title}</h2>
    //           <p className="card_para">{item.description}</p>
    //         </div>
    //       ))
    //     }
    //   </div>
    // </div>
  )
}

export default Hero
