import React, { useEffect, useState } from "react";
import "./Material.scss";
import { SlDocs } from "react-icons/sl";
import { BiLink } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaRegEdit } from "react-icons/fa";
import { generate, generateFirst, masterRequest } from "../../helpers/requests";
import { ToastContainer, toast } from "react-toastify";

const Material = () => {
  const delay = 1500;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const contents = useSelector((state) => state.master.contents);

  const cards = [
    {
      id: 1,
      icon: <SlDocs />,
      title: "Upload Files",
      // description: "PDF, .ePub, .doc, .ppt,",
      description: "PDF",

      count: `${contents.file.count} file${
        contents.file.count != 1 ? "s" : ""
      } added`,
      link: "/uploadfile",
    },
    {
      id: 2,
      icon: <BiLink />,
      title: "From URL",
      description: "",
      count: `${contents.url.count} link${
        contents.url.count != 1 ? "s" : ""
      } added`,
      link: "/linkadd",
    },
    {
      id: 3,
      icon: <FaRegEdit />,
      title: "Paste your text",
      description: "",
      count: `${contents.note.count} note${
        contents.note.count != 1 ? "s" : ""
      } added`,
      link: "/noteadd",
    },
  ];



  const generateQuestions = async () => {
    let _contents = [
      ...contents.file.data,
      ...contents.url.data,
      ...contents.note.data,
    ];
    let _count = contents.file.count + contents.url.count + contents.note.count;
    if (!_count) {
      toast.warning("Please add some files, notes or urls first!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      return;
    }
    const availableNotes = _contents.filter(
      (_content) => _content.questions_created != 1
    );
    if (!availableNotes.length) {
      toast.warning("All files, notes or urls are processed", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
      });
      return;
    }
    setLoader(true);
    toast.success("Generating questions in background...", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
    });
    setTimeout(() => {
      toast.dismiss();
      navigate("/mcq");
      setLoader(false);
    }, 2000);
    let res = await generate({ dispatch, data: {} });
  };

  useEffect(() => {
    const masterReq = async () => {
      const res = masterRequest({ dispatch });
    };
    masterReq();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="main_material">
        <div className="material_content">
          <div className="material_text">
            <h2 className="material_title">
              Upload your <br />
              study material
            </h2>
            <p className="material_para">
              Whatever you are using to get
              <br /> ready for the exam.
            </p>
          </div>
          <div className="main_card">
            {cards !== undefined &&
              cards.length > 0 &&
              cards.map((e) => {
                return (
                  <Link
                    to={e.link}
                    style={{ textDecoration: "none", color: "unset" }}
                  >
                    <div
                      className={`card ${
                        e.id == 1 ? "cards_spec" + e.id : null
                      }`}
                    >
                      <div className="upper_sec">
                        <div className="icon">{e.icon}</div>
                        <div className="card_text">
                          <h4 className="card_title">{e.title}</h4>
                          {e.description && (
                            <p className="card_para">{e.description}</p>
                          )}
                        </div>
                      </div>
                      <p className="count">{e.count}</p>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
        <div className="material_bottom">
          <button
            className="button"
            style={{ height: "40px", width: "250px" }}
            disabled={loader}
            onClick={() => generateQuestions()}
          >
            {loader ? "Generating questions..." : "Generate questions"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Material;
