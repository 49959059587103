import "./TopicCompletedAlert.scss";
import TopicImg1 from "../../assets/topics/topic-2.png";
import { useEffect, useState } from "react";
import WaveSVG from "../WaveSVG/WaveSVG";
import { useNavigate } from "react-router-dom";
import { getUserAuthToken, getApiUrl,CreateGtagEvent } from "../../helpers/requests";
import {install} from 'ga-gtag';
import Sidebar from "../Sidebar/Sidebar";


export default function TopicCompletedAlert() {
  const navigate = useNavigate();
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();
  const [userrole, setUserRole] = useState("");
  const [userdetails, setUserDetails] = useState("");
  const [percentage, set_percentage] = useState(60);
  const [topic_score, set_topic_score] = useState(0);
  const [topic_title, set_topic_title] = useState("");

  var dataJson = [
    {
      name: "Sample topic 1 ",
      score: 60,
      status: "unlocked",
    },
  ];


  const [data, setData] = useState({
    question_id: "",
    score: "",
    title: "",
  });

  const [topic_content, set_topic_content] = useState([
    {
      "random_id": "133",
      "status": "unlocked",
      "name": "Sample topic 1 ",
      "score": 60,
    },
  ]);

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    let percentage_value = 60;
    if(urlParams.get('percentage') !== null){
      percentage_value = parseInt(urlParams.get('percentage'));
      set_percentage(percentage_value)
    }
    if(urlParams.get('score') !== null){
      let x = parseInt(urlParams.get('score'));
      set_topic_score(x)
    }
    if(urlParams.get('title') !== null){
      let x = urlParams.get('title');
      set_topic_title(x)
    }

    let topic_id = undefined;
    let percentage = undefined;
    if(urlParams.get('topic_id') !== null){
      topic_id = urlParams.get('topic_id');
    }
    if(urlParams.get('percentage') !== null){
      percentage = parseInt(urlParams.get('percentage'));
    }
    const body = {
      "topic_id": topic_id,
      "percentage": percentage
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    };
    fetch(`${apiUrl}/topic/turnoff-popup`, requestOptions);
  },[])

  useEffect(() => {
    var userDetails = localStorage.getItem("user");
    const userId = localStorage.getItem('userId')
    if (userDetails) {
      var userData = JSON.parse(userDetails);
      setUserDetails(userData);
      install('G-K9ES37HT6F', {debugger:true, 'send_page_view': false }); 
      // CreateGtagEvent('first_concept_completed',{            
      //   user_id:userId,         
      // })
      
    }
  }, [topic_content]);

  useEffect(() => {
    let keyOfObj;
    let valueOfObj;
    try {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.forEach((value, key) => {
        if (key == "resource_id" || key == "topic_id" || key == "question_id") {
          set_topic_content((prevContent) => [
            {
              ...prevContent[0],
              random_key: key,
            },
            ...prevContent.slice(1),
          ]);
        }

        if (key == "resource_id" || key == "topic_id" || key == "question_id") {
          set_topic_content((prevContent) => [
            {
              ...prevContent[0],
              random_id: value,
            },
            ...prevContent.slice(1),
          ]);
        }

        if (key == "score") {
          set_topic_content((prevContent) => [
            {
              ...prevContent[0],
              score: value,
            },
            ...prevContent.slice(1),
          ]);
        }
        if (key == "title") {
          set_topic_content((prevContent) => [
            {
              ...prevContent[0],
              name: value,
            },
            ...prevContent.slice(1),
          ]);
        }
      });
    } catch (e) {
      console.error(e);
    }
  }, []);


  const handleNavigate = () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("filter_status") !== null && urlParams.get("filter_status") === "off") {
      navigate(`/quiz`);
    } else {
      if (topic_content[0].random_key == "question_id") {
        navigate(`/home`);
      }
  
      if (topic_content[0].random_key == "topic_id") {
        navigate(
          `/quiz?${topic_content[0].random_key}=${topic_content[0].random_id}`
        );
      }
  
      if (topic_content[0].random_key == "resource_id") {
        navigate(
          `/quiz?${topic_content[0].random_key}=${topic_content[0].random_id}`
        );
      }
  
      if (topic_content[0].random_key == undefined) {
        navigate(`/quiz`);
      }
    }
  };


  function handleUnlock() {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach((value, key) => {
      if (key == "topic_id") {
        const body = {
          topic_id: value,
        };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(body),
        };
        fetch(`${apiUrl}/topic/turnoff-popup`, requestOptions)
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              navigate(`/pushmode`);
            } else {
              navigate(`/pushmode`);
            }
            // set_topic_content(response.data)
            // set_topic_content(dataJson);
          });
      }
    });
  }

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      // body: JSON.stringify(body),
    };
    fetch(`${apiUrl}/user-info`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          setUserRole(response.data[0].plan_type);
          localStorage.setItem("plan_type", response.data[0].plan_type);
          localStorage.setItem("plan_end_date", response.data[0].plan_end_date);
        }
      });
  }, []);

  return (
    <>
    {/* <Sidebar/> */}
      <main className="topic-limit-exceed-popup-container">
        <div className="tlep-wrapper">
          <h2 className="tlep-heading"
            style={percentage === 60 ? {display: "block"}: {display: "none"}}>
            {userdetails ? userdetails.name : 'Alec'} , your are almost there! 👏
          </h2>
          <h2 className="tlep-heading" 
            style={percentage === 100 ? {display: "block"}: {display: "none"}}>
            {userdetails ? userdetails.name : 'Alec'} , you are doing amazing! 👏
          </h2>
          <div className="tlep-topic-card-container">
            {topic_content.length ? (
              <>
                {topic_content.map((item, index) => {
                  let heightVar = 0;

                  // if (topic_score > 0 && topic_score <= 10) {
                  //   heightVar = 450;
                  // } else if (topic_score > 10 && topic_score <= 20) {
                  //   heightVar = 400;
                  // } else if (topic_score > 20 && topic_score <= 30) {
                  //   heightVar = 300;
                  // } else if (topic_score > 30 && topic_score <= 40) {
                  //   heightVar = 200;
                  // } else if (topic_score > 40 && topic_score <= 50) {
                  //   heightVar = 100;
                  // } else if (topic_score > 50 && topic_score <= 60) {
                  //   heightVar = -100;
                  // } else if (topic_score > 60 && topic_score <= 70) {
                  //   heightVar = -200;
                  // } else if (topic_score > 70 && topic_score <= 80) {
                  //   heightVar = -300;
                  // } else if (topic_score > 80 && topic_score <= 90) {
                  //   heightVar = -400;
                  // } else if (topic_score > 90 && topic_score <= 100) {
                  //   heightVar = -450;
                  // }

                  if(percentage === 60){
                    heightVar = -50;
                  }
                  return (
                    <div 
                      style={percentage === 100 ? {backgroundColor: "#fff234"} : {backgroundColor: "#fff"}}
                      className="tlep-topic-levels-card">
                      <p>{topic_title}</p>
                      {/* <p>{data.title}</p> */}

                      <WaveSVG
                        data={heightVar}
                      />
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>
            
            {/* {
              percentage === 60
                ?
                  "It seems you are getting very strong on this Concept"
                : 
                  "Keep using dende to hold your Knowledge Score"
            } */}
          <p className="tlep-text1" style={percentage === 60 ? {display: "block"}: {display: "none"}}>
            It seems you are getting very strong on this topic 
          </p>
          <p className="tlep-text1" style={percentage === 100 ? {display: "block"}: {display: "none"}}>
            Keep using dende to hold your Knowledge Score
          </p>
          {userrole == "free" ? (
            <div onClick={() => handleUnlock()} className="tlep-btn-container">
              <button>Unlock all Concepts</button>
            </div>
          ) : null}
          <p
            className={userrole == "free" ? "tlep-text3" : "tlep-text2"}
            onClick={() => handleNavigate()}>
            Keep practicing &nbsp;{`>`}
          </p>
        </div>
      </main>
    </>
  );
}

//   function getTopicInfo(topic_id) {
//     const body = {
//       topic_id: topic_id,
//     };
//     const requestOptions = {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + token,
//       },
//       body: JSON.stringify(body),
//     };
//     fetch(`${apiUrl}/topic`, requestOptions)
//       .then((response) => response.json())
//       .then((response) => {
//         // set_topic_content(response.data)
//         set_topic_content(dataJson);
//       });
//   }
