import React, { useState, useEffect, useRef } from "react";

import { Drawer, ButtonToolbar, Button, Placeholder } from "rsuite";
import { BiLink } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { RxMixerHorizontal } from "react-icons/rx";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import "./Filter.scss";
import { useSelector } from "react-redux";

const Filter = ({
  question,
  setFileIds,
  setQuestionTypes,
  resource,
  indexOfItem,
  findItem,
}) => {

  const contents = useSelector((state) => state.master.contents);
  const [open, setOpen] = useState(false);
  const [next, setNext] = useState(0);
  const location = useLocation();
  const filterRef = useRef(null);

  const library = location.pathname == "/library";
  const chapters = location.pathname == "/chapters";
  const quiz = location.pathname == "/mcq";

  const [selectedTypes, setSelectedTypes] = useState(["MCQ", "open"]);
  const [selectedFiles, setSelectedFiles] = useState([
    ...contents.file.data.map((file) => file.id),
    ...contents.url.data.map((file) => file.id),
    ...contents.note.data.map((file) => file.id),
  ]);

  const openFilter = (event) => {
    setOpen(true);
  };

  const closeFilter = () => {
    setOpen(false);
  };

  const toggleTypes = (type) => {
    const typeIndex = selectedTypes.indexOf(type);
    let types = selectedTypes.slice();
    if (typeIndex > -1) {
      if (types.length > 1) {
        types.splice(typeIndex, 1);
        setSelectedTypes(types);
      }
    } else {
      types.push(type);
      setSelectedTypes(types);
    }
  };

  const toggleFiles = (id) => {
    const typeIndex = selectedFiles.indexOf(id);
    let ids = selectedFiles.slice();
    if (typeIndex > -1) {
      if (ids.length > 1) {
        ids.splice(typeIndex, 1);
        setSelectedFiles(ids);
      }
    } else {
      ids.push(id);
      setSelectedFiles(ids);
    }
  };

  useEffect(() => {
    if (typeof setFileIds == "function") {
      setFileIds(selectedFiles);
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (typeof setQuestionTypes == "function") {
      setQuestionTypes(selectedTypes);
    }
  }, [selectedTypes]);

  useEffect(() => {
    if (contents) {
      let contentIds = [
        ...contents.file.data.map((file) => file.id),
        ...contents.url.data.map((file) => file.id),
        ...contents.note.data.map((file) => file.id),
      ];
      if (typeof setFileIds == "function") {
        setFileIds(contentIds);
      }
    }
  }, [contents]);

  useEffect(() => {
    if (typeof setFileIds == "function") {
      setFileIds(selectedFiles);
    }
    if (typeof setQuestionTypes == "function") {
      setQuestionTypes(selectedTypes);
    }
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const targetElement = event.target;
      const filterElement = filterRef.current;
      // Check if the click is outside the filter content or on the close button
      if (
        filterElement &&
        !filterElement.contains(targetElement) &&
        !targetElement.classList.contains("close-btn")
      ) {
        closeFilter();
      } else if (targetElement.classList.contains("close-btn")) {
        closeFilter();
      }
    };

    if (open) {
      setTimeout(() => {
        document.addEventListener("click", handleOutsideClick);
      }, 0);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [open]);

  const toggleTitles = (file, index) => {
    findItem(file, index);
  };

  return (
    <div className="mixer_btn" onClick={openFilter}>
      <div className="main_filter">
        <ButtonToolbar>
          <div>
            {" "}
            <RxMixerHorizontal />{" "}
          </div>
        </ButtonToolbar>

        <Drawer open={open}>
          <Drawer.Body
            style={{
              borderBottomLeftRadius: "20px",
              borderTopLeftRadius: "20px",
              height: "100%",
            }}
            ref={filterRef}
          >
            <div className="filter_content">
              <h3 className="title">
                {library
                  ? "Library Filters"
                  : chapters
                  ? "Chapters Filters"
                  : "Quiz Filter"}
                <AiOutlineClose
                  onClick={() => closeFilter()}
                  style={{
                    color: "#4D4D4D",
                    cursor: "pointer",
                    padding: "3px",
                  }}
                  className="close-btn"
                />
              </h3>
              <div className="top_sec">
                {library && (
                  <div className="btn_sec">
                    <button>Draft</button>
                    <button>Complete</button>
                  </div>
                )}
                {location.pathname !== "/carousel" && (
                  <>
                    <div className="sub_heading">Type of questions</div>
                    <div className="btn_sec">
                      <button
                        className={
                          selectedTypes.indexOf("MCQ") > -1
                            ? "selected"
                            : "not-selected"
                        }
                        onClick={() => toggleTypes("MCQ")}
                      >
                        MCQs
                      </button>
                      <button
                        className={
                          selectedTypes.indexOf("open") > -1
                            ? "selected"
                            : "not-selected"
                        }
                        onClick={() => toggleTypes("open")}
                      >
                        Open ended
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="centre_sec">
                <div className="sub_heading">Files</div>
                <div className="btn_sec">
                  {/* {contents.file.count > 0 &&
                    contents.file.data.map((file, index) => {
                      return (
                        <button
                          className={
                            selectedFiles.indexOf(file.id) > -1
                              ? "selected"
                              : "not-selected"
                          }
                          onClick={() => toggleFiles(file.id)}
                          key={"file_button_" + index}
                        >
                          {file.title}
                        </button>
                      );
                    })} */}
                  {resource.length ? (
                    <>
                      {resource.map((title, index) => (
                        <>
                          <button
                            // className={"selected"}
                            className={
                              indexOfItem == index ? "selected" : "not-selected"
                            }
                            onClick={() => toggleTitles(title, index)}
                            key={"file_button_" + index}
                          >
                            {title.resource_title}
                          </button>
                        </>
                      ))}
                    </>
                  ) : null}
                </div>
              </div>
              <p></p>
              <div className="centre_sec">
                <div className="sub_heading">Links</div>
                <div className="btn_sec">
                  {contents.url.count > 0 && contents.url.data.length > 0
                    ? contents.url.data.map((url, index) => {
                        return (
                          <button
                            className={
                              selectedFiles.indexOf(url.id) > -1
                                ? "selected"
                                : "not-selected"
                            }
                            onClick={() => toggleFiles(url.id)}
                            key={"filter_" + index}
                          >
                            <BiLink
                              style={{ color: "#4D4D4D", fontSize: "20px" }}
                            />{" "}
                            {url.url}
                          </button>
                        );
                      })
                    : "No link is added yet."}
                </div>
              </div>
              <p></p>
              <div className="centre_sec">
                <div className="sub_heading">Notes</div>
                <div className="btn_sec">
                  {contents.note.count > 0 && contents.note.data.length > 0
                    ? contents.note.data.map((note, index) => {
                        return (
                          <button
                            className={
                              selectedFiles.indexOf(note.id) > -1
                                ? "selected"
                                : "not-selected"
                            }
                            onClick={() => toggleFiles(note.id)}
                            key={"link_" + index}
                          >
                            {note.title}
                          </button>
                        );
                      })
                    : "No note added yet."}
                </div>
              </div>
            </div>

            {/* ////////////////////////////////////////// */}

            {/* {!tips &&
                    <div className="filter_result">

                        <h3 className="title">
                            Questions
                            <AiOutlineClose onClick={cloefilfil} style={{ color: '#4D4D4D', cursor: 'pointer' }} />
                        </h3>

                        <div className="top_sec">
                            <div className="sub_heading" style={{ backgroundColor: '#F0F6F5', color: '#201E1F' }}>What is the "tragedy of the commons"?</div>
                            <div className="sub_heading">Question 2?</div>
                            <div className="sub_heading">Question 3?</div>
                            <div className="sub_heading">Question 4?</div>
                            <div className="sub_heading">Question 5?</div>
                            <div className="sub_heading">Question 6?</div>
                            <div className="sub_heading">Question 7?</div>
                            <div className="sub_heading">Question 8?</div>
                        </div>

                        <div className="come">
                            <div className="icon">
                                <AiOutlinePlus />
                            </div>
                            come on, more question!
                        </div>

                    </div>
} */}
          </Drawer.Body>
        </Drawer>
      </div>
    </div>
  );
};

export default Filter;
