import { actionTypes } from "../constants/action-types";


const token = localStorage.getItem('token');
const userId = localStorage.getItem('userId');
const userString = localStorage.getItem('user');
const user = userString ? JSON.parse(userString) : null;

const initialState = {
    token: token,
    userId: userId,
    data: user
}

export const userReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case actionTypes.SET_USER:
      return { ...state, ...payload };
    default:
      return state;
  }
};
