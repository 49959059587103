import "./GeneratedTopics.scss";
import { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserAuthToken, getApiUrl,CreateGtagEvent, getTopicsListAPI } from "../../helpers/requests";
import LoaderSpinner from "../../Components/LoaderInitial/LoaderInitial";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Grid } from "swiper/modules";
import { ToastContainer, toast } from "react-toastify";
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import dende_logo from "../../assets/dende-new-logo.png"

export default function GeneratedTopics() {
  const [topic_list_array, Set_topic_list_array] = useState([]);
  const [slide_count, set_slide_count] = useState(4);
  const navigate = useNavigate();
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();
  let global_resource_id = useRef(null);

  const intervalRef = useRef(null);
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [plan_type, set_plan_type] = useState(localStorage.getItem("plan_type"));

  const intervalFunctionIDsArray = useRef([]);
  const intervalTimeLimit = useRef(0);

  useEffect(()=>{
    handleTopicList();

    if(window.innerWidth > 1000){
      set_slide_count(4);
    } else if(window.innerWidth > 450 && window.innerWidth <= 1000){
      set_slide_count(2);
    } else if(window.innerWidth <= 450){
      set_slide_count(2);
    }

    const handleResize = () => {
      if(window.innerWidth > 1000){
        set_slide_count(4);
      } else if(window.innerWidth > 450 && window.innerWidth <= 1000){
        set_slide_count(2);
      } else if(window.innerWidth <= 450){
        set_slide_count(2);
      }
    };
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
    window.removeEventListener('resize', handleResize);
    stopIntervalUtility()
    };
  },[]);


  async function handleTopicList(){
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get("resource_id") !== null){
      global_resource_id.current = urlParams.get("resource_id");
      const userId = localStorage.getItem('userId')
      const body = {
        "resource_id": global_resource_id.current 
        };
      let apiResponse = await getTopicsListAPI(body);
      if(apiResponse.data.length){

        handleMarketingData()
        // CreateGtagEvent('concepts_extraction',{            
        //   user_id:userId, 
        //   resource_id:global_resource_id.current,
        // });

        Set_topic_list_array(apiResponse.data);
        setLoaderStatus(false);
      } else {
        setLoaderStatus(true);
        // let intervalIDArray = [];
        // intervalIDArray = intervalFunctionIDArray.current;
        let intervalID = setInterval(()=>{
          checkTopicsAvailability()
        },10000);
        intervalFunctionIDsArray.current.push(intervalID);
      }
    }
  }



  function handleMarketingData(){
    // google analytics
    // CreateGtagEvent('concepts_extraction',{            
    //   user_id: String(localStorage.getItem("userId")) || "", 
    //   resource_id: global_resource_id.current,
    // });
    // google tag manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Concept_extraction",
      user_id: String(localStorage.getItem("userId")) || "",
    });
  }



  async function checkTopicsAvailability(){
    intervalTimeLimit.current += 10;
    try{
      if(intervalTimeLimit.current >= 300){
        toast.warning("Question generation takes longer than expected, please checkout after sometime. Redirect to home page", {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(()=>{
          navigate(`/home`);
        }, 5000);
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get("resource_id") !== null){
          global_resource_id.current = urlParams.get("resource_id");
          const userId = localStorage.getItem('userId')
          const body = {
            "resource_id": global_resource_id.current 
            };
          let apiResponse = await getTopicsListAPI(body);
          if(apiResponse.data.length){
            stopIntervalUtility();

            handleMarketingData();
            // CreateGtagEvent('concepts_extraction',{       
            //   user_id:userId, 
            //   resource_id:global_resource_id.current,
            // });

            Set_topic_list_array(apiResponse.data);
            setLoaderStatus(false);
          } else {
            setLoaderStatus(true);
          }
        }
      }
    } catch(e){
      navigate(`/login`);
      stopIntervalUtility();
    }
  }


  function stopIntervalUtility(){
    intervalFunctionIDsArray.current.forEach((item)=>{
      clearInterval(item);
    })
  }

  return (
    <>
      <main className="generated-topics-container">
        <div className="generated-topics-wrapper" style={{ display: loaderStatus ? "none" : "" }}>
          <div className="generated-topics-logo">
            <img onClick={()=>{navigate("/home")}} src={dende_logo} alt="logo" />
          </div>
          <div className="gen-topics-heading">
            <div className="gen-topics-heading-left">
              <h2>
                We generated{" "}
                <span>
                  {
                    topic_list_array.length
                      ?
                      '+'+topic_list_array[0].total_question_count
                      :
                        "+16"
                  }
                  {" "}questions</span> from your document
              </h2>
            </div>
            <div className="gen-topics-heading-right">
              <button
                id="initial_file_upload_see_free_question"
                onClick={() => {
                  navigate(`/quiz?resource_id=${global_resource_id.current}`);
                }}
              >
                {
                  plan_type === "free" 
                  ?
                    <>
                      <h6>See free questions</h6>
                      <p>from first pages</p>
                    </>
                  :
                    <>
                      <h6>Go to Quiz</h6>
                    </>
                }
              </button>
            </div>
          </div>
          <div className="gen-topics-card-list">
              {
                topic_list_array.length
                ?
                  <>
                  <Swiper
                    modules={[Grid, Pagination]}
                    slidesPerView={slide_count}
                    grid={{
                      rows: slide_count === 2 ? 3 : 2,
                      fill: "row"
                    }}
                    grabCursor={true}
                    keyboard={{
                      enabled: true,
                    }}
                    spaceBetween={0}
                    pagination={{
                      clickable: true,
                    }}
                    loop={false}
                    className="mobile-main-swiper"
                    style={ slide_count === 2 && topic_list_array[0].topic_list.length > 4 ? {height:"570px", width: "100%"} : {height: "400px", width: "100%"}}
                  >
                  {
                    topic_list_array[0].topic_list.map((item, index) => {
                      return (
                        <SwiperSlide
                          // style={slide_count === 2 ? {height:"80px"} : {height: `calc((100% - 30px) / 2`}}
                          style={slide_count === 2 && topic_list_array[0].topic_list.length ? {height: `calc((100% - 30px) / 3`} : {height: `calc((100% - 30px) / 2`}}
                          // style={{height: "80px"}}
                          key={index}>
                          <div className="gen-topics-card-new">
                            <h6>
                              {/* API response modified which affects */}
                              {item["topic_name"].length > 25 ? item["topic_name"].substring(0, 25)+"..." : item["topic_name"] }
                            </h6>
                            <p>
                              {
                                item["pages"] === ""
                                  ?
                                    ""
                                  :
                                  "pages "+item["pages"]
                              }
                            </p>
                            <div className="gen-topics-progress-bar"></div>
                          </div>
                        </SwiperSlide>
                      );
                    })
                  }                  
                  </Swiper>
                  </>
                :
                  ""
              }
          </div>
        </div>
      </main>
      <LoaderSpinner data={loaderStatus}></LoaderSpinner>
      <ToastContainer/>
    </>
  );
}