import React, { useEffect, useRef, useState } from "react";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { getConceptQuestionAPI } from "../../helpers/requests";
import styles from "./ConceptQuestion.module.css"
import playIcon from "../../assets/new/play.svg"
import playIconWithCircle from "../../assets/new/playIconWithCircle.svg"
import { useNavigate } from "react-router-dom";
import {ReactComponent as LeftArrowIcon} from "../../assets/new/left-arrow.svg"


export default function ChapterQuestions(){

	const [showLoadingGif, setShowLoadingGif] = useState(false);
	const [apiResponseJson, setApiResponseJson] = useState({});
	const [window_width, set_window_width] = useState(window.innerWidth);
	const navigate = useNavigate();

	useEffect(() => {
		setShowLoadingGif(true);
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get("topic_id") !== null && urlParams.get("topic_id") !== undefined) {
			console.log('----------------------')
			console.log(urlParams.get("topic_id"))
			console.log('----------------------')
			getConceptQuestions(urlParams.get("topic_id"), urlParams.get("resource_id"))
		}
		const handleResize = () => {
			set_window_width(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	async function getConceptQuestions(topicId, resourceId){
		let apiResponse = await getConceptQuestionAPI({topicId: topicId, resourceId: resourceId});
		if(apiResponse && apiResponse.data.question_list.length > 0){
			setApiResponseJson(apiResponse.data);
			setShowLoadingGif(false);
		}
	}

	function showProgessBar(answered, wrong, unanswered){
        return(
            <>
                <div className={styles["progress-bar"]}>
					{
						answered > 0
						?
							<div 
								style={{width:`${answered}%`, backgroundColor:"var(--light-green)"}}
								className={`${styles["progress-bar-child"]}`}>
							</div>
						:
							null
					}
					{
						wrong > 0
						?
							<div 
								style={{width: `${wrong}%`, backgroundColor:"var(--light-orange)"}}
								className={`${styles["progress-bar-child"]}`}>
							</div>
						:
							null
					}
					{
						unanswered > 0
						?
							<div 
								style={{width: `${unanswered}%`, backgroundColor:"var(--light-blue)"}}
								className={`${styles["progress-bar-child"]}`}>
							</div>
						:
							null
					}

                </div>
            </>
        )
    }

	function displayCurrentTopicFileName(fileName){
		if(window_width >= 500){
			if(fileName.length <= 40){
				return fileName;
			} else {
				return `${fileName.substring(0, 40)}...`;
			}
		} else if (window_width < 500){
			return `${fileName.substring(0, 18)}...`;
		}
	}

	function getQuestionStatusColor(status){
		if (status === "correct"){
			return "var(--light-green)";
		} else if(status === "wrong"){
			return "var(--light-orange)";
		} else if (status === "unanswered"){
			return "var(--light-blue)";
		} else if (status === "reread"){
			return "var(--refresh-violet)";
		}
	}

	return (
	<>
	{
		apiResponseJson && Object.keys(apiResponseJson).length > 0
		?
			<>
				<main className={`${styles["container"]}`}>
					<div className={`${styles["content-wrapper"]}`}>
						<div onClick={()=>{navigate("/concept")}} className={`${styles["back-btn"]}`}>
							<LeftArrowIcon/>
							<span>back to questions</span>
						</div>
						<div className={`${styles["question-list"]}`}>
							<div className={`${styles["topic-header"]}`}>
								<div className={`${styles["topic-header-left"]}`}>
									<div className={`${styles["topic-header-title"]}`}>
										{apiResponseJson.topic_name}
									</div>
									<div className={`${styles["topic-header-description"]}`}>
										<p>Pages {apiResponseJson.start_page} - {apiResponseJson.end_page}</p>
										<p>|</p>
										<p>{displayCurrentTopicFileName(apiResponseJson.resource_name)}</p>
									</div>
								</div>
								<div className={`${styles["topic-header-right"]}`}>
									<div className={`${styles["topic-header-progress-bar"]}`}>
										{showProgessBar(apiResponseJson.num_correct_questions, apiResponseJson.num_wrong_questions, apiResponseJson.num_unanswered_questions)}
									</div>
									<div className={`${styles["topic-header-question-count"]}`}>
										{apiResponseJson.question_list.length} questions
									</div>
								</div>
							</div>
							<div className={`${styles["question-list-header"]}`}>
								<div className={`${styles["header-column-1"]}`}>
									Questions
								</div>
								<div className={`${styles["header-column-2"]}`}>
									Status
								</div>
							</div>
							{
								apiResponseJson.question_list.length > 0
								?
									<>
										{apiResponseJson.question_list.map((questionItem, questionIndex)=>(
											<div 
												className={`${styles["question-card"]}`} 
												key={questionIndex}
												onClick={()=>{navigate(`/quiz?questionId=${questionItem.question_id}&redirect=conceptQuestion&topic_id=${apiResponseJson.topic_id}&resource_id=${apiResponseJson.resource_id}`)}}
											>
												<div className={`${styles["question-card-left"]}`}>
													<div className={`${styles["question-title"]}`}>
														{questionItem.question_content.question}
													</div>
												</div>
												<div className={`${styles["question-card-right"]}`}>
													<aside 
													style={{backgroundColor: getQuestionStatusColor(questionItem.question_status)}}>
													</aside>										
												</div>
											</div>
										))}
									</>
								:
									<></>
							}
						</div>
					</div>
				</main>
			</>
		:
		null
	}
	<LoaderSpinner data={showLoadingGif}></LoaderSpinner>
	</>
	);
};
